import { useEffect, useRef } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { columnsheadId } from '../../universal/columnshead';
import systematic from '../../../config/systematic';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { Button, Tooltip } from 'antd';
import antMessage from '../../../utils/extend/AntdNotification'
import fetchApi from '../../../utils/api/fetchApi';
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';

const RoleOrUserLicenseExtend = (props: any) => {
    const ref = useRef<any>(undefined);
    /** 使用类型 userLicense or roleLicense */
    const useType = props.useType;
    const { info, closeModal } = props;
    const { id, licenseId, licenseName } = info
    const notificationApi = useNotificationInstance()

    /** 后端数据wmService */
    const wmService = systematic.wmService;
    let title = '[ ' + licenseName + ' ] 扩展权限';
    /** 刷新数据 */
    let initData: any;
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;

        }
        const fetchData = async () => {
            // fetchApi.GET(wmService,
            //     {
            //         apiId: 'generalData',
            //         apiVariable: 'licenseType',
            //         apiExtend: 'showList'
            //     }).then(res => {
            //         if (res.success) {
            //             setLicenseTypeData(res.data);
            //         }
            //     });
        };
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const handleOk = (e: any) => {
        closeModal();
    };

    /** 更新数据 */
    const handleUpdate = () => {
        fetchApi.PUT(wmService,
            {
                apiId: useType + 'Extend',
                apiExtend: 'updateData',
                apiVariable: id,
                apiData: {
                    licenseId
                }
            }).then(res => {
                if (res.success) {
                    initData();
                     showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message:"更新数据成功",
                                    description:  "success"
                                });;
                }
            });
    };

    /** 表头内容 */
    const columnsHead = [
        {
            title: '扩展',
            dataIndex: 'licenseExtend',
            render: (text: any) => <Tooltip placement="topLeft" title={text.exId}>{
                text.exName
            }</Tooltip>,
        }
    ];
    /** 表头 */
    const columns = columnsheadId
        .concat(columnsHead as []);
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: title,
        apiId: useType === 'roleLicense' ? 'roleLicenseExtend' : 'userLicenseExtend',
        columns,
        showStates: true,
        showOperation: true,
        showEdit: false,
        showAdd: false,
        showQuery: false,
        sendPageData: {
            filterId: id
        },
    };
    return (
        <>
            <AntDraggableModal
                title={
                    <>
                        {title}
                        <Button style={{ float: "right" }} onClick={handleUpdate}>更新数据</Button>
                    </>}

                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={850} >
                <WebUniversal props={extendProps} ref={ref} />
            </AntDraggableModal>
        </>
    )
}

export default RoleOrUserLicenseExtend;
