import systemConfig from '../../../config/systematic';
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Space, Table, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import dayjs from 'dayjs';
import antMessage from '../../../utils/extend/AntdNotification'
import DownloadExcel from '../../report/DownloadExcel';
import { QuestionCircleFilled } from '@ant-design/icons';
import { SocketContext } from '../../../App';
import arrayHandle from '../../../utils/baseLib/arrayHandle';
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';
const { RangePicker } = DatePicker;
/** 表头内容 */
let columnsHead: Array<any> = [
    {
        title: "序号",
        dataIndex: "serial",
        width: 5,
        key: "serial",
        header: "序号",
        sortBy: 1,
        init: true,

    },
    {
        title: "房名",
        dataIndex: "roomName",
        width: 10,
        key: "roomName",
        header: "房名",
        sortBy: 2,
        init: true,
    },
    {
        title: <div>单数<Tooltip title="统计订单人数不为0的单数"><QuestionCircleFilled /></Tooltip> </div>,
        // title: "单数",

        dataIndex: "counts",
        key: "counts",
        width: 10,
        header: "单数",
        sortBy: 3,
        init: true,

    },
    {
        title: <div>续单<Tooltip title="统计订单人数为0的单数"><QuestionCircleFilled /></Tooltip> </div>,
        dataIndex: "reCounts",
        key: "reCounts",
        width: 10,
        header: "续单",
        sortBy: 4,
        init: true,

    },
    {
        title: <div>金额(实收)<Tooltip title="实收金额，包括退货"><QuestionCircleFilled /></Tooltip> </div>,
        dataIndex: "totalRealMoney",
        key: "totalRealMoney",
        width: 20,
        header: "金额(实收)",
        sortBy: 5,
        init: true,

    },
    {
        title: <div>金额(退款)<Tooltip title="退款金额(特指线上退款)"><QuestionCircleFilled /></Tooltip> </div>,
        dataIndex: "refundAmount",
        key: "refundAmount",
        width: 20,
        header: "金额(退款)",
        sortBy: 6,
        init: true,
    },
    {
        title: <div>金额(实入)<Tooltip title="实收金额-退款金额"><QuestionCircleFilled /></Tooltip> </div>,
        dataIndex: "realIncome",
        key: "realIncome",
        width: 20,
        header: "金额(实入)",
        sortBy: 7,
        init: true,

    },
    {
        title: "人数",
        dataIndex: "totalHeadCount",
        key: "totalHeadCount",
        width: 12,
        header: "人数",
        sortBy: 8,
        init: true,


    },
    {
        title: "人均",
        dataIndex: "perCapita",
        key: "perCapita",
        width: 10,
        header: "人均",
        sortBy: 9,
        init: true,
    },
    {
        title: "金额(赠送)",
        dataIndex: "totalGiftPrice",
        key: "totalGiftPrice",
        width: 12,
        header: "金额(赠送)",
        sortBy: 10,
        //   init: true,


    },

    {
        title: "金额(折扣)",
        dataIndex: "totalAgioMoney",
        key: "totalAgioMoney",
        width: 10,
        header: "金额(折扣)",
        sortBy: 11,


    },
    {
        title: "应收服务费",
        dataIndex: "totalShouldServiceCharge",
        key: "totalShouldServiceCharge",
        width: 10,
        header: "应收服务费",
        sortBy: 12,
    },
    {
        title: "实收服务费",
        dataIndex: "totalServiceCharge",
        key: "totalServiceCharge",
        width: 10,
        header: "实收服务费",
        sortBy: 13,
        init: true,

    },
    {
        title: "减免服务费",
        dataIndex: "totalDerateServiceCharge",
        key: "totalDerateServiceCharge",
        width: 10,
        header: "减免服务费",
        sortBy: 14,


    },




]
/**房台统计 */
export default function RoomPlatformStatistics(props: any) {
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [form] = Form.useForm();
    const [sbData, setSbData] = useState<any>();
    const [sbName, setSbName] = useState<string>("所有");
    const [roomTypeName, setRoomTypeName] = useState<string>("所有");
    const [rows, setRows] = useState<Array<any>>([]);
    const [roomTypeData, setRoomTypeData] = useState<Array<any>>();
    const [roomData, setRoomData] = useState<Array<any>>();
    const [branchStoreFloor, setBranchStoreFloor] = useState<Array<any>>();

    // console.log("initHead",initHead);

    const [branchStoreFloorName, setBranchStoreFloorName] = useState<any>("所有")
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState<Array<any>>([]);
    const [userName, setUserName] = useState<string>("")
    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;
    const notificationApi = useNotificationInstance()

    useEffect(() => {
        const fetchData = async () => {
            form.setFieldsValue({
                "chooseTime": [dayjs(), dayjs()]
            })
            let initHead = columnsHead.filter((item: any) => {
                if (item.init) {
                    return item
                }
            })

            setSelectedColumns(initHead)
            if (contextData?.currentBranchStoreId) {
                if (contextData?.refresh) {
                  await initArgument();
        
                }else{
                    await initArgument()
                }
              }
         
        }
        fetchData()

      
    }, [contextData?.currentBranchStoreId])
    const handleColumnToggle = (key: any) => {
        setSelectedColumns((prev: any) => {
            const newColumns = [...prev];
            const index = newColumns.findIndex(column => column.key === key);
            if (index > -1) {
                newColumns.splice(index, 1);
            } else {
                newColumns.push(columnsHead.find((column: any) => column.key === key));
            }
            setIsAllSelected(newColumns.length === columnsHead.length);
            return newColumns;
        });
    };
    const handleSelectAll = (e: any) => {
        setIsAllSelected(e.target.checked);
        setSelectedColumns(e.target.checked ? columnsHead : []);
    };
    /** 表头内容 */
    // let columnsHead: any = [
    //     // {
    //     //   title: "采购商",
    //     //   dataIndex: "buyerName",
    //     // },
    //     {
    //         title: "房名",
    //         dataIndex: "roomName",
    //         width: 110
    //     },
    //     {
    //         title: <div>单数<Tooltip title="统计订单人数不为0的单数"><QuestionCircleFilled /></Tooltip> </div>,
    //         dataIndex: "counts",
    //     },
    //     {
    //         title: <div>续单<Tooltip title="统计订单人数为0的单数"><QuestionCircleFilled /></Tooltip> </div>,
    //         dataIndex: "reCounts",
    //     },
    //     {
    //         title: <div>金额(实收)<Tooltip title="实收金额，包括退货"><QuestionCircleFilled /></Tooltip> </div>,
    //         dataIndex: "totalRealMoney",

    //     },
    //     {
    //         title: <div>金额(退款)<Tooltip title="退款金额(特指线上退款)"><QuestionCircleFilled /></Tooltip> </div>,
    //         dataIndex: "refundAmount",

    //     },
    //     {
    //         title: <div>金额(实入)<Tooltip title="实收金额-退款金额"><QuestionCircleFilled /></Tooltip> </div>,
    //         dataIndex: "realIncome",

    //     },
    //     {
    //         title: "人数",
    //         dataIndex: "totalHeadCount",
    //     },
    //     {
    //         title: "人均",
    //         dataIndex: "perCapita",
    //     },
    //     {
    //         title: "金额(赠送)",
    //         dataIndex: "totalGiftPrice",
    //     },
    //     {
    //         title: "金额(折扣)",
    //         dataIndex: "totalAgioMoney",
    //     },
    //     {
    //         title: "应收服务费",
    //         dataIndex: "totalShouldServiceCharge",
    //     },
    //     {
    //         title: "实收服务费",
    //         dataIndex: "totalServiceCharge",
    //     },
    //     {
    //         title: "减免服务费",
    //         dataIndex: "totalDerateServiceCharge",
    //     },
    // ]
    const initArgument = async () => {

        let sb = await fetchApi.GET(wmService, {
            apiId: "sb",
            apiExtend: 'getBrandStoreIdToSb',
            apiData: {
                branchStoreId: contextData?.currentBranchStoreId
            }
        })

        if (sb.success) {
            setSbData(sb.data)
        }
        fetchApi.GET(wmService,
            {
                apiId: 'room',
                apiExtend: 'showList',
                apiData: {
                    branchStoreId: contextData?.currentBranchStoreId,
                    extendObj: {
                        menuHeadsIdNum: -1,
                        printersIdNum: -1,
                    },
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                        }
                    },
                }
            }).then(res => {

                if (res.success) {
                    setRoomData(res.data);
                }
            })

        let roomType = await fetchApi.GET(wmService, {
            apiId: "roomType",
            apiExtend: 'showList',
            apiData: {
                branchStoreId: contextData?.currentBranchStoreId,
                extendObj: {
                    menuHeadsIdNum: -1,

                },
                mountDataOptions: {
                    userMount: {
                        isDept: true,
                    }
                },
            }
        })
        if (roomType.success) {

            setRoomTypeData(roomType.data)
        }
        let branchStoreFloorData = await fetchApi.GET(wmService, {
            apiId: "branchStoreFloor",
            apiExtend: 'showList',
            apiData: {
                mountDataOptions: {
                    userMount: {
                        isEmployees: true,
                        isDept: true
                    }
                },
                sendPageData: {
                    branchStoreId: contextData?.currentBranchStoreId,
                }


            }
        })

        if (branchStoreFloorData.success) {

            setBranchStoreFloor(branchStoreFloorData.data)
        }
        form.resetFields(["sbId", "deptIds", "roomTypeIds", "roomIds"])
        setContextData({ ...contextData, refresh: false })
    }
    const onFinish = () => {
        let branchStoreId = contextData?.currentBranchStoreId;
        if (!branchStoreId) {
            antMessage("error", '错误', "必须选择分店");
            return false
        }
        form.validateFields()
            .then(async (values: any) => {
                fetchApi.GET(wmService, {
                    apiId: "financeReport",
                    apiExtend: 'roomPlatformStatistics',
                    apiData: {
                        ...values,
                        // branchStoreId:"d648d6a0b27c11ed9cb12187c1fd477a"
                        branchStoreId
                    }
                }).then((res) => {

                    if (res.success) {
                        setUserName(res.data.userName)
                        setRows(res.data.rows)
                    }
                }).catch((err) => {
                     showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message: '错误',
                                    description: err,
                                });;
                })
            })
            .catch((err) => {

                 showCustomNotification(notificationApi!, {
                                    type: 'warning',
                                    message:"无法通过数据校验",
                                description:  "请输入所需数据!"
                                });;
            })


    }
    const changeSb = (e: any, option: any) => {

        if (option?.children) setSbName(option.children)

    }

    const changedRoomType = (e: any, option: any) => {
        let roomTypeName: Array<any> = []
        for (let i = 0; i < option.length; i++) {
            roomTypeName.push(option[i].children)
        }
        setRoomTypeName(roomTypeName.join("|"))
    }

    const changedBranchStoreFloor = (e: any, option: any) => {
        let branchStoreFloorName: Array<any> = []
        for (let i = 0; i < option.length; i++) {
            branchStoreFloorName.push(option[i].children)
        }
        setBranchStoreFloorName(branchStoreFloorName.join("|"))
    }

    return (
        <>
            {/* <BranchStoreList form={form} setRoomData={setRoomData} setBranchStoreFloor={setBranchStoreFloor} setSbData={setSbData} setRoomTypeData={setRoomTypeData} ></BranchStoreList> */}
            <Row style={{ marginTop: 16 }}>
                <Col span={2}>
                    <Checkbox onChange={handleSelectAll} checked={isAllSelected}>
                        全选
                    </Checkbox>
                </Col>
                {columnsHead.map((column: any) => (
                    <Col key={column.key} span={2}>
                        <Checkbox
                            checked={selectedColumns.some((sc: any) => sc.key === column.key)}
                            onChange={() => handleColumnToggle(column.key)}
                        >
                            {column.title}
                        </Checkbox>
                    </Col>
                ))}
            </Row>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Row>

                    <Col span={6}>
                        <Form.Item
                            label="市别"
                            name="sbId"
                        >
                            <Select
                                onChange={changeSb}
                                allowClear
                                placeholder="市别"
                                optionFilterProp="children"
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(sbData, 'sbName')}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={10}>
                        <Form.Item
                            label="分店楼层"
                            name="branchStoreFloorIds"
                        >
                            <Select
                                // orderTypeData
                                mode="multiple"
                                showSearch
                                allowClear
                                placeholder="请选择房型!"
                                optionFilterProp="children"
                                onChange={changedBranchStoreFloor}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(branchStoreFloor, 'floor')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label="房型"
                            name="roomTypeIds"
                        >
                            <Select
                                // orderTypeData
                                mode="multiple"
                                showSearch
                                allowClear
                                placeholder="请选择房型!"
                                optionFilterProp="children"
                                onChange={changedRoomType}
                                onSearch={(e) => getList.search('roomType', setRoomTypeData, e, { andWhereObj: { branchStoreId: localStorage.getItem("currentBranchStoreId") } })}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(roomTypeData, 'roomType')}
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col span={16}>
                        <Form.Item
                            label="房间"
                            name="roomIds"
                        >
                            <Select
                                // orderTypeData
                                mode="multiple"
                                allowClear
                                showSearch
                                placeholder="请选择房间!"
                                optionFilterProp="children"
                                //   onChange={changedRoomType}
                                onSearch={(e) => getList.search('room', setRoomData, e, { andWhereObj: { branchStoreId: localStorage.getItem("currentBranchStoreId") } })}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(roomData, 'roomNum')}
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col span={6}>
                        <Form.Item
                            label="日期"
                            name="chooseTime"
                        >
                            <RangePicker
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item

                            label="时间"
                            name="time"
                        >
                            <RangePicker
                                picker={"time"}
                                format="H"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    统计
                                </Button >
                                <Button onClick={() => DownloadExcel(rows, "roomPlatformStatistics", { date: form.getFieldValue("chooseTime"), sbName, userName, roomTypeName, columnsHead: arrayHandle.sort(selectedColumns, "sortBy", "asc") })}>下载EXCEL文件</Button>

                                {/* purContrast */}
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

            <div style={{ marginTop: "20px" }}>
                <Table
                    size={"middle"}
                    pagination={false}
                    // rowKey={(record: any, index: any) => {
                    //     return index
                    // }}
                    columns={arrayHandle.sort(selectedColumns, "sortBy", "asc")}

                    dataSource={rows}

                >
                </Table>

            </div>
        </>
    )
}
