import systemConfig from "../config/systematic";
import fetchApi from "../utils/api/fetchApi";
import antMessage from '../utils/extend/AntdNotification'
import { showCustomNotification, useNotificationInstance } from "../utils/extend/AntdNotificationEx";




   /** 后端数据wmService */
   let wmService: any = systemConfig.wmService;
interface extraParameter {
    formName: string,
    // form: any,
    type: string,
    // data: any,
    setModal: any,
    setData: any,
    orderType: number
}

const orderPeration = () => {

}
orderPeration.cancel = (apiId: any, id: any, initData: any, isCancelAndAdd: boolean, extraParameter?: extraParameter) => {

    if (isCancelAndAdd) {
        //需要打开取消窗口

        const { setData, setModal, formName, type, orderType } = extraParameter as any;
        setData({ id, apiId, formName, type, orderType })
        setModal(true)


    } else {
        //不需要打开取消窗口

        const { orderType } = extraParameter as any;

        fetchApi.POST(wmService, {
            apiId: "orderHandle",
            apiExtend: "orderCancel",
            apiData: { orderType,orderId:id }
        }).then(res => {
            initData()
            // setLoading(false)
            antMessage('success', '成功', 'success');
        }).catch(err => {
            // setLoading(false)
            //  showCustomNotification(notificationApi!, {
            //                         type: 'error',
            //                         message:"错误，无法保存",
            //                         description:  err.message + ' | status：' + err.errors.status,
            //                     });
        })
    }
    //刷新数据
    // initData()
}


export default orderPeration;