import React, { useContext, useEffect, useRef } from 'react';
import { Switch } from 'antd';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import QueuingTypeEdit from './QueuingTypeEdit';
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification';
import systemConfig from '../../../config/systematic';
import { SocketContext } from '../../../App';
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';

export default function QueuingType(props: any) {

    const ref = useRef<any>(undefined);

    /** api服务id */
    const apiId = 'queuingType';
    const notificationApi = useNotificationInstance()

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }

        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
            }
        }

        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextData?.currentBranchStoreId]); //初始化数据

    const refresh = (status: Boolean) => {
        if (status === true) {
            const currentData: initDataType = {
                apiId,
            }
            ref.current.initData(currentData);
        }
    }

    const onRefresh = (item: any) => {
        const { branchStoreId } = item;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                branchStoreId
            }
        }
        ref.current.initData(currentData);

        setContextData({ ...contextData, refresh: false })
    }

    //改变数据
    const handleChange = async (id: any, columnName: string, isValue: boolean) => {

        const bodys = {
            apiId: "queuingType",
            apiVariable: id,
            apiExtend: "updateSingle",
            apiData: {
                columnName,
                isValue
            }
        }
        fetchApi.PUT(wmService, bodys).then(res => {
            showCustomNotification(notificationApi!, {
                type: 'success',
                message: '编辑成功',
                description: 'success',
            });;
            refresh(true);
        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '类型名称',
            dataIndex: 'queuingTypeName',
        },
        {
            title: '叫号规则',
            dataIndex: 'callRules',
        },
        {
            title: '人数',
            dataIndex: 'people',
            render: (text: any, record: any) => {
                return (
                    <div>{record.minPeople + "~" + record.maxPeople}</div>
                )
            }
        },
        {
            title: '排队序号',
            dataIndex: 'segment',
            sorter: true,
        },
        {
            title: '微信拿号',
            dataIndex: 'isWx',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isWx', e)}
                />
            )
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '排队类型',
        apiId,
        showQuery: false,
        showStates: true,
        columns,
        pagination: true,
        EditComponent: QueuingTypeEdit,
        sendPageData: { branchStoreId: contextData?.currentBranchStoreId },
        // sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };

    return (
        <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
    )
}