import { Suspense, createContext, useEffect, useMemo, useState } from 'react';
import './App.css';
import { useRoutes, } from "react-router-dom";
import routers from './routers/index';
import wsServer, { modeType } from './utils/system/wsServer';
import systematic from './config/systematic';
import antMessage from './utils/extend/AntdNotification';
import fetchApi from './utils/api/fetchApi';
import systemConfig from './config/systematic';
import { getQueryStringArgs } from './components/routerMag/RoutingTransit';
import { notification } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';
import '@ant-design/v5-patch-for-react-19';
/**
 * 封装一个可以用于请求数据的工具函数
 * @returns 
 */
function fetchValue() {
  let status = "pending";
  let result: any = [];
  new Promise(async (resolve, reject) => {
    resolve(await routers())
  }).then((res) => {
    status = "resolved";
    result = res;
  })
  return {
    read() {
      if (status === "resolved") {
        return result;
      }
      if (status === "rejected") {
        throw Promise.reject(null);
      }
      if (status === "pending") {
        throw Promise.resolve(null);
      }
    },
  };
};

/**
 * ws监听
 * @param data 
 */
const wsListener = async (data: any) => {
};

/**
 * wsServer连接成功后的回调函数
 * @param socket 
 */
const wsConnectSuccess = (socket: any) => {
  const successMessage = 'WS服务器连接成功，sokcetId: ' + socket.id
  console.log(successMessage);
  // const notificationApi = useNotificationInstance()
  antMessage("success", successMessage);
  // showCustomNotification(notificationApi!, {
  //   type: 'success',
  //   message: successMessage,
  // })
  //保存socketId
  localStorage.setItem('socketId', socket.id);
};

/**
 * ws 连接错误时的回调
 */
const wsConnectError = async () => {
  const errorMessage = 'WS服务器连接错误，请检查连接!';
  console.log(errorMessage);
  antMessage('error', errorMessage);
  //清空socketId
  localStorage.setItem('socketId', '');
};

/**
 * ws断开连接
 * @param reason 
 * @param details 
 */
const wsDisconnectListener = async (socketId: string, options: { reason: any, details: any }) => {
  const { reason, details } = options;

  const errorMessage = 'WS服务器断开连接, socketId: ' + socketId + ', reason: ' + reason + ', details: ' + details;
  console.log(errorMessage);
  antMessage('error', errorMessage);
  //清空socketId
  localStorage.setItem('socketId', '');
};

/** wsServer */
const ws = new wsServer();

/**
 * ws连接
 * @param token 
 */
export const wsConnect = (mode: modeType,
  options: {
    /** 登录id */
    logId?: string,
    /** 登录密码 */
    logPass?: string,
    /** token */
    token?: string,
  }) => {
  const { logId, logPass, token } = options;
  ws.connect({
    serverUrl: systematic.wsServer.url,
    nameSpace: systematic.wsServer.service.wm,
    path: systematic.wsServer.path,
    listenerId: 'system',
    // wsListener,
    licenseTypeId: 'licenseManage',
    // roomType: 'system',
    auth: {
      mode,
      logId,
      logPass,
      token,
      mountDataOptions: {
        userMount: {
          isDept: true
        }
      }
    }
  }, {
    connectSuccessListener: wsConnectSuccess,
    connectErrorListener: wsConnectError,
    disconnectListener: wsDisconnectListener
  }).then(async (_res: any) => {
    // console.log("res",_res);
    // console.log("ws",ws);
    // console.log("wsddd",ws.connected);



    // console.log('WS Server Connection succeeded! socketId: ' + res.socket.id);
  }).catch(err => {
    console.log('WS Server Connection Failed!');
    console.log(err);
  })
};

export const wsDisconnect = () => {
  if (ws) {
    ws.disconnect();
  }
};

/** 请求动态路由数据 */
const fv = fetchValue();

interface config {
  wsServer: wsServer,
  contextData?: any,
  setContextData?: any,
  api?: NotificationInstance
}
/**
 * 路由组件
 * @returns 
 */
const RouterCom = () => {
  const routers = fv.read();
  return useRoutes(routers)
};
const SocketContext = createContext<config>({ wsServer: ws });
/** Application */
const App = () => {
  const [api, contextHolder] = notification.useNotification();
  const defaultService = systemConfig.wmService;
  let [args, setArgs] = useState<any>(getQueryStringArgs())
  const [contextData, setContextData] = useState<any>();
  let newContextData: any = {
    ...contextData
  }

  useEffect(() => {
    const fetchData = () => {
      let currentBranchStoreId = localStorage.getItem("currentBranchStoreId");
      let currentBranchStoreName = localStorage.getItem("currentBranchStoreName");
      let currentBrandName = localStorage.getItem("currentBrandName");
      let currentBrandId = localStorage.getItem("currentBrandId");

      if (currentBranchStoreId !== "null" && currentBranchStoreId !== "undefined" && typeof currentBranchStoreId !== "undefined") {
        newContextData.currentBranchStoreId = currentBranchStoreId;
      }
      if (currentBranchStoreName !== "null" && currentBranchStoreName !== "undefined" && typeof currentBranchStoreName !== "undefined") {
        newContextData.currentBranchStoreName = currentBranchStoreName;

      }
      if (currentBrandName !== "null" && currentBrandName !== "undefined" && typeof currentBrandName !== "undefined") {
        newContextData.currentBrandName = currentBrandName;

      }
      if (currentBrandId !== "null" && currentBrandId !== "undefined" && typeof currentBrandId !== "undefined") {
        newContextData.currentBrandId = currentBrandId;
      }

      try {

        let token = args?.token ? args?.token : (localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token'))
        if (args && args.url) {

          sessionStorage.setItem('token', token);
        }
        if (token) {
          fetchApi.GET(defaultService,
            {
              apiId: 'configNodes',
              apiExtend: 'getConfig',
              apiData: {
                id: 'config',
                nodeId: "users",
                isUser: true,

              }
            }, {}, token)
            .then(res => {
              const { users } = res.data;
              newContextData.currentEntrepotName = users?.entrepotName;
              newContextData.currentEntrepotId = users?.entrepotId;


              sessionStorage.setItem("userName", res.data.userName)
            })

        }



      } catch (err) {
        console.log("err", err);

      }
    }

    setContextData(newContextData)


    fetchData()

  }, [])

  return (
    <>
      <SocketContext.Provider value={{ contextData, api, setContextData, wsServer: ws }}>
        <Suspense fallback={<>loading</>}>
          <RouterCom />
        </Suspense>
        {contextHolder}
      </SocketContext.Provider>
      {/* <NotificationProvider /> */}

    </>

  )
}

export default App;
export { SocketContext };
