import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import systemConfig from '../../../config/systematic';
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebProductFormList from '../../generalComponents/WebProductFormList';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification'
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';


export default function CancelOrder(props: any) {

    const [form] = Form.useForm(); //form 
    const { data, closeModal } = props;


    const [deleteData, setDeleteData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false)
    const [formList, setFormList] = useState<any>({ formName: data.formName, form, type: data.type, setDeleteData, data: { id: data.id }, apiId: data.apiId })
    /**调入部门数据 */
    const [importDeptData, setImportDeptData] = useState<any>();
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    /**调出仓库数据 */
    const [exEntrepotData, setExEntrepotData] = useState<any>();

    /**调入仓库数据 */
    const [imEntrepotData, setImEntrepotData] = useState<any>();

    const notificationApi = useNotificationInstance()
    /**仓库数据 */
    const [entrepotData, setEntrepotData] = useState<any>();
    /**调出部门数据 */
    const [exportDeptData, setExportDeptData] = useState<any>();

    /**采购商数据 */
    const [buyerData, setBuyerData] = useState<any>();
    /**部门数据 */
    const [deptData, setDeptData] = useState<any>();
    const handleOk = (e: any) => {
        form.validateFields() //数据校验
            .then(async (values: any) => {

                fetchApi.POST(wmService, {
                    apiId: "orderHandle",
                    apiExtend: "orderCancelAndCreate",
                    apiData: { 
                        ...values, orderType: data.orderType, createdAt: sessionStorage.getItem("date"), oldOrderId: values.id,
                        mountDataOptions:{
                            userMount: {
                                isUserLogin:true,
                            }
                           },
                     }
                }).then(res => {
                    setLoading(false)
                    // setLoading(false)
                    antMessage('success', '成功', 'success');
                    closeModal(true);
                }).catch(err => {
                    // setLoading(false)
                    setLoading(false)
                     showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message:"错误，无法保存",
                                    description:  err.message + ' | status：' + err.errors.status,
                                });
                })

            })
            .catch((err: any) => {
                console.log("err", err);
                setLoading(false)
                // setLoading(false)
                 showCustomNotification(notificationApi!, {
                                    type: 'warning',
                                    message:"无法通过数据校验",
                                description:  "请输入所需数据!"
                                });;
            });
        // closeModal(true)
    };
    const handleCancel = (e: any) => {
        closeModal(true)
    };
    useEffect(() => {


        const fetchData = async () => {

            httpGet(wmService, {
                apiId: "entrepot",
                apiExtend: 'showList'
            }).then(res => {
                setImEntrepotData(res.data)
                setExEntrepotData(res.data)
                setEntrepotData(res.data)
            })
            httpGet(wmService, {
                apiId: "dept",
                apiExtend: 'showList',
                apiData:{
                    
                }
            }).then(res => {

                setImportDeptData(res.data)
                setExportDeptData(res.data)
                setDeptData(res.data)

            })
            httpGet(wmService, {
                apiId: "buyerType",
                apiExtend: 'getAreaIdToBuyer',
                apiData:{
                    mountDataOptions: {
                        userMount: {
                            isEmployees: true,
                        }
                    }
                }
            }).then((res) => {
                setBuyerData(res.data)

            })

        }
        fetchData()
    }, [])
    return (
        <>
            <AntDraggableModal
                title={'取消并新增订单'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                confirmLoading={loading}
                maskClosable={false}
                width={"65%"}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.altKey) {
                            if (e.key === "s" && e.altKey) {
                                handleOk(e)
                            }
                        }

                    }}
                >
                    <Row >

                        <Col span={6}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        {data.orderType === 3 ? <>
                            <Col span={8}>
                                <Form.Item
                                    label="调出部门"
                                    name="exportDeptId"
                                    rules={[{ required: true, message: '请输入调出部门!' }]}>
                                    <Select
                                        // disabled={data.type === "NEW" ? false : true}
                                        showSearch
                                        placeholder="请输入调出部门"
                                        optionFilterProp="children"
                                        onSearch={(e) => getList.searchEx('dept', setExportDeptData, e, undefined, {
                                            form,
                                            setObj: ["exportDeptId"],
                                            setObjValue: ["id"],
                                        })}
                                        filterOption={(input, option: any) => {
                                            return true
                                        }}>
                                        {getList.general(exportDeptData, 'deptName')}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    label="调出仓库"
                                    name="exportEntrepotId"
                                    rules={[{ required: true, message: '请输入调入仓库!' }]}
                                >
                                    <Select
                                        // disabled={data.type === "NEW" ? false : true}
                                        showSearch
                                        placeholder="请输入调出仓库"
                                        optionFilterProp="children"
                                        onSearch={(e) => getList.searchEx('entrepot', setExEntrepotData, e, undefined, {
                                            form,
                                            setObj: ["exportEntrepotId"],
                                            setObjValue: ["id"],
                                        })}
                                        filterOption={(input, option: any) => {
                                            return true
                                        }}>

                                        {getList.general(exEntrepotData, 'entrepotName')}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="调入部门"
                                    name="importDeptId"
                                    rules={[{ required: true, message: '请输入调入部门!' }]}>
                                    <Select
                                        // disabled={data.type === "NEW" ? false : true}
                                        showSearch
                                        onSearch={(e) => getList.searchEx('dept', setImportDeptData, e, undefined, {
                                            form,
                                            setObj: ["importDeptId"],
                                            setObjValue: ["id"],
                                        })}
                                        placeholder="请输入调入部门"
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) => {
                                            return true
                                        }}>
                                        {getList.general(importDeptData, 'deptName')}
                                    </Select>
                                </Form.Item>
                            </Col>



                            <Col span={8}>
                                <Form.Item
                                    label="调入仓库"
                                    name="importEntrepotId"
                                    rules={[{ required: true, message: '请输入调入仓库!' }]}
                                >
                                    <Select
                                        // disabled={data.type === "NEW" ? false : true}
                                        showSearch
                                        placeholder="请输入调入仓库"
                                        optionFilterProp="children"
                                        onSearch={(e) => getList.searchEx('entrepot', setImEntrepotData, e, undefined, {
                                            form,
                                            setObj: ["importEntrepotId"],
                                            setObjValue: ["id"],
                                        })}
                                        filterOption={(input, option: any) => {
                                            return true
                                        }}>

                                        {getList.general(imEntrepotData, 'entrepotName')}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </> : null}


                        {data.orderType === 2 ? <>
                            <Col span={9}>
                                <Form.Item
                                    label="部门"
                                    name="deptId"
                                    rules={[{ required: true, message: '请输入部门!' }]}>
                                    <Select
                                        // disabled={data.type === "NEW" ? false : true}
                                        showSearch
                                        placeholder="部门"
                                        optionFilterProp="children"
                                        onSearch={(e) => getList.searchEx('dept', setDeptData, e, undefined,
                                            {
                                                form,
                                                setObj: ["deptId"],
                                                setObjValue: ["id"],
                                            }

                                        )}
                                        filterOption={(input, option: any) => {
                                            return true
                                        }}>
                                        {getList.general(deptData, 'deptName')}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    label="采购商"
                                    name="buyerId"
                                    rules={[{ required: true, message: '请输入采购商!' }]}>
                                    <Select
                                        onSearch={(e) => getList.searchEx('buyer', setBuyerData, e, buyerData, {
                                            setObj: ["buyerId"],
                                            setObjValue: ["id"],
                                            form
                                        })}
                                        // disabled={data.type === "NEW" ? false : true}
                                        showSearch
                                        placeholder="请输入采购商"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return true
                                        }
                                        }
                                        options={getList.generalSelcetOption(buyerData, "buyerName")}
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="仓库"
                                    name="entrepotId"
                                    rules={[{ required: true, message: '请输入仓库!' }]}>
                                    <Select
                                        showSearch
                                        placeholder="仓库"
                                        // onChange={onChangeEntrepotIdToBuyerData}
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {getList.general(entrepotData, 'entrepotName')}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </> : null}



                        <WebProductFormList props={formList}></WebProductFormList>


                    </Row>
                </Form>
            </AntDraggableModal>

        </>
    )
}
