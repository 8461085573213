import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Tooltip } from 'antd';
import moment from 'moment';
import antMessage from '../../../utils/extend/AntdNotification'
import systemConfig from '../../../config/systematic';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import CostTransferOrderEdit from '../cost/CostTransferOrderEdit';
import dayjs from 'dayjs';
import "./table.css"
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';


const { RangePicker } = DatePicker;
export default function CostTrOrderQuery() {
  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  const [current, setCurrent] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [initValues, setInitValues] = useState<any>();
  const [imDeptData, setImDeptData] = useState<any>();
  const [exDeptData, setExDeptData] = useState<any>();
  const notificationApi = useNotificationInstance()

  let loading = false;
  const [aa, setAa] = useState<Array<any>>([
    {
      id: "productName",
      name: "品名"
    },
    {
      id: "selfId",
      name: "品号"
    }
  ])

  const [bb, setBb] = useState<Array<any>>([
    {
      id: "=",
      name: "相等"
    },
    {
      id: "like",
      name: "相似"
    }
  ])
  const [form] = Form.useForm();

  /**打开调拨订单窗口 */
  const [modalCostTrOrderOpen, setModalCostTrOrderOpen] = useState<boolean>(false);
  /**传递收货订单的数据 */
  const [costTrOrderData, setCostTrOrderData] = useState<any>();
  const [rows, setRows] = useState<Array<any>>([])
  /** 表头内容 */
  let columnsHead: any = [
    {
      title: "编号",
      dataIndex: "id",
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 4 ? text.substring(0, 4) + '....' : text)
          : ''
      }</Tooltip>,
      width:70
      // render: (text: any) => (text ? text.selfId ? text.selfId : "" : "")
    },
    {
      title: "单号",
      dataIndex: "costTransferOrderId",
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 4 ? text.substring(0, 4) + '....' : text)
          : ''
      }</Tooltip>,
      width:70
    },
    {
      title: "日期",
      dataIndex: "costTransferOrder",
      align: "center",
      render: (val: any, record: any) =>
        <span style={{fontSize:13}}>
          {moment(val.time).format('YYYY-MM-DD')}
        </span>,
         width:110

    },
    {
      title: "品号",
      dataIndex: "product",
      render: (text: any) => (text ? text.selfId ? text.selfId : "" : ""),
      width:60
    },

    {
      title: "品名",
      dataIndex: "product",
      render: (text: any) => (text ? `${text.productName}${text.area ? `(${text.area?.areaName})` : ""}` : ""),
      width:160
    },
    {
      title: "单位",
      dataIndex: "product",
      render: (text: any) => (text ? text.productUnit.productUnitName : ""),
      width:80
    },
    {
      title: "规格",
      dataIndex: "productSpecifications"
    },
    {
      title: "数量",
      dataIndex: "bNum",
      render: (text: any) => (text ? text.toFixed(2) : 0),
      width:100
    },
    {
      title: "单价",
      dataIndex: "univalence",
      render: (text: any) => (text ? text.toFixed(2) : 0),
      width:100
    },
    {
      title: "总金额",
      dataIndex: "totalPrice",
      render: (text: any) => (text ? Number(text).toFixed(2) : 0),
      width:130
    },
    {
      title: "备注",
      dataIndex: "memo"
    },
    {
      title: "操作",
      dataIndex: 'operation',
      render: (text: any, record: any) =>
        <Space size="small">
          <Button size={"small"} type="text" onClick={() => cc(record, "EDIT")}>编辑</Button>
        </Space>
    },
  ]

  const cc = (record: any, type: any) => {
    setCostTrOrderData({ id: record.costTransferOrderId, type })
    setModalCostTrOrderOpen(true)
  }
  const onCloseCostTrOrderOpen = (status: Boolean) => {
    setModalCostTrOrderOpen(false)
    if (status === true) {
    }
  }


  const initData = (values: any, currentEx?: number, pageSizeEx?: number) => {

    fetchApi.GET(
      wmService,
      {
        apiId: "costReport",
        apiExtend: 'costTrOrderQuery',
        apiData: {
          ...values,
          pageSize: pageSizeEx ? pageSizeEx : pageSize,
          current: currentEx ? currentEx : current,

        }
      }
    ).then(res => {

      setTotal(res.data.total)
      setPageSize(res.data.pageSize)
      setCurrent(res.data.current)
      setInitValues(values)
      setRows(res.data.rows)
       showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message:"搜索成功",
                                description:  "success"
                                });;

    }).catch((err) => {
      antMessage('error', '搜索失败', err);

    })


  }

  const onFinish = () => {

    form.validateFields()
      .then((values: any) => {

        initData({
          ...values,
          time: values.chooseTime ? {
            startTime: dayjs(values.chooseTime[0]).format("YYYY-MM-DD"),
            endTime: dayjs(values.chooseTime[1]).format("YYYY-MM-DD"),
          } : null,
        })
        // fetchApi.GET(
        //   wmService,
        //   {
        //     apiId: "costReport",
        //     apiExtend: 'costTrOrderQuery',
        //     apiData: {
        //       ...values
        //     }
        //   }
        // ).then(res => {
        //   setRows(res.data)
        //    showCustomNotification(notificationApi!, {
                                //     type: 'success',
                                //     message:"搜索成功",
                                // description:  "success"
                                // });;

        // }).catch((err) => {
        //   console.log("err", err);

        // })
      }).catch((err) => {
         showCustomNotification(notificationApi!, {
                                    type: 'warning',
                                    message:"无法通过数据校验",
                                description:  "请输入所需数据!"
                                });;

      })






  }
  useEffect(() => {
    const fetchData = async () => {
      let deptData = await fetchApi.GET(wmService, {
        apiId: "dept",
        apiExtend: 'showList',
        apiData:{
                    
        }
      })
      if (deptData.success) {
        setExDeptData(deptData.data)
        setImDeptData(deptData.data)

      }


    }
    fetchData()
  }, [])
  // 表格分页属性
  const paginationProps = {
    showSizeChanger: true,
    showQuickJumper: false,
    showTotal: () => `共${total}条`,
    pageSize,
    current,
    total,
    onShowSizeChange: (current: number, pageSize: number) => changePageSize(pageSize, current),
    onChange: (current: number) => changePage(current, pageSize),
  };

  // 回调函数，切换页数
  const changePage = (current: number, pageSize: number) => {
    if (loading === false) {
      initData(initValues, current, pageSize);
      loading = true;
    }
  }

  // 回调函数,每页显示多少条
  const changePageSize = (pageSize: number, current: number) => {

    if (loading === false) {
      initData(initValues, current, pageSize)
      loading = true;
    }
  };
  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ chooseTime: [dayjs(), dayjs()] }}
      >
        <Row>

          <Col span={6}>
            <Form.Item label="参数"
              name="parameters"
              rules={[{ required: true, message: '请选择参数!' }]}
            >
              <Select
                // mode="multiple"
                showSearch
                placeholder="参数"
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(aa, 'name')}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="条件"
              name="condition"
              rules={[{ required: true, message: '请选择条件!' }]}
            >
              <Select
                // mode="multiple"
                showSearch
                placeholder="条件"
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(bb, 'name')}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="参数值"
              name="parametersValue"
              rules={[{ required: true, message: '请输入参数值!' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="日期"
              name="chooseTime"
            >
              <RangePicker

                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              label="调出部门"
              name="exportDeptIds"
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="请选择调出部门!"
                optionFilterProp="children"

                onSearch={(e) => getList.search('dept', setExDeptData, e)}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(exDeptData, 'deptName')}
              </Select>
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              label="调入部门"
              name="importDeptIds"
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="请选择调入部门!"
                optionFilterProp="children"

                onSearch={(e) => getList.search('dept', setImDeptData, e)}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(imDeptData, 'deptName')}
              </Select>
            </Form.Item>
          </Col>



          <Col span={2}>
            <Form.Item >
              <Space>
                <Button type="primary" htmlType="submit">
                  查询
                </Button >
                {/* <Button onClick={() => DownloadExcel(prop, prop.data)} >
                                  下载EXCEL文件
                              </Button> */}
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>



      {/* <Button onClick={() => DownloadExcel(rows, "moonReport",queryCriteria)}>下载EXCEL文件</Button> */}
      <div style={{ marginTop: "20px" }}>
        <Table
          pagination={paginationProps}
          rowKey={(record: any) => record.id}
          columns={columnsHead}
          dataSource={rows}
          summary={(pageData: any) => {

            let totalPrice = 0;
            let totalBNum = 0;

            if (pageData.length > 0) {
                pageData.map((item: any) => {
                    if (item.totalPrice) totalPrice += item.totalPrice;
                    if (item.bNum) totalBNum += item.bNum;
                })
            }
            return (
                <Table.Summary fixed>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>

                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={3}></Table.Summary.Cell>
                        <Table.Summary.Cell index={4}></Table.Summary.Cell>
                        <Table.Summary.Cell index={5}></Table.Summary.Cell>
                        <Table.Summary.Cell index={6}></Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>{totalBNum?totalBNum.toFixed(2):0}</Table.Summary.Cell>
                        <Table.Summary.Cell index={8}></Table.Summary.Cell>
                        <Table.Summary.Cell index={9}>{totalPrice ? totalPrice.toFixed(2) : 0}</Table.Summary.Cell>
                       
                        <Table.Summary.Cell index={10}></Table.Summary.Cell>
                        <Table.Summary.Cell index={11}></Table.Summary.Cell>


                    </Table.Summary.Row>
                </Table.Summary>
            )
        }}
        >
        </Table>
        {modalCostTrOrderOpen ? (<CostTransferOrderEdit data={costTrOrderData} closeModal={onCloseCostTrOrderOpen}
        />)
          : null}
      </div>
    </>
  )
}
