import React, { useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Tooltip } from 'antd';
import systemConfig from '../../../../config/systematic';
import fetchApi from '../../../../utils/api/fetchApi';
import getList from '../../../universal/getList';
import PurchaseOrderEdit from '../../orderManagement/PurchaseOrderEdit';
import moment from 'moment';
import antMessage from '../../../../utils/extend/AntdNotification'
import entrepotPrint from '../../../../generalMethod/entrepotPrint';
import survey from '../../../../generalMethod/survey';
import WebUniversalReport, { WUExtendPropsType } from '../../../system/WebUniversalReport';
import dayjs from 'dayjs';
import { showCustomNotification, useNotificationInstance } from '../../../../utils/extend/AntdNotificationEx';


const { RangePicker } = DatePicker;
export default function PurOrderQuery() {
  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  const notificationApi = useNotificationInstance()

  const [aa, setAa] = useState<Array<any>>([
    {
      id: "productName",
      name: "品名"
    },
    {
      id: "selfId",
      name: "品号"
    }
  ])

  const [bb, setBb] = useState<Array<any>>([
    {
      id: "=",
      name: "相等"
    },
    {
      id: "like",
      name: "相似"
    }
  ])
  const [form] = Form.useForm();

 
  const [initValues, setInitValues] = useState<any>();

  
  /** 表头内容 */
  let columnsHead: any = [
    {
      title: "编号",
      dataIndex: "id",
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 8 ? text.substring(0, 6) + '....' : text)
          : ''
      }</Tooltip>
      // render: (text: any) => (text ? text.selfId ? text.selfId : "" : "")
    },
    {
      title: "单号",
      dataIndex: "orderId",
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 8 ? text.substring(0, 6) + '....' : text)
          : ''
      }</Tooltip>
    },
    {
      title: "日期",
      dataIndex: "createdAt",
      align: "center",

      render: (val: any, record: any) =>
        <span>
          {moment(val).format('YYYY-MM-DD')}
        </span>,

    },
    {
      title: "品号",
      dataIndex: "selfId",
    },

    {
      title: "品名",
      dataIndex: "productName",
    },
    {
      title: "单位",
      dataIndex: "productUnitName",
    },
    {
      title: "规格",
      dataIndex: "productSpecifications",
    },
    {
      title: "数量",
      dataIndex: "bNum",
      align: "center",

      render: (text: any) => (text ? text.toFixed(2) : 0)
    },
    {
      title: "单价",
      dataIndex: "univalence",
      align: "center",

      render: (text: any) => (text ? text.toFixed(2) : 0)
    },
    {
      title: "金额",
      dataIndex: "totalPrice",
      align: "center",

      render: (text: any) => (text ? Number(text).toFixed(2) : 0)
    },
    {
      title: "备注",
      dataIndex: "memo"
    },
    {
      title: '打印数',
      dataIndex: 'printNumber',
      align: "center",

    },
    // {
    //   title: '打印',
    //   dataIndex: 'print',
    //   align: "center",
    //   render: (text: any, record: any) => (<Button disabled={record.orderState === 99 && record.orderStatus === 3 ? false : true} size='small' type='primary' onClick={() => entrepotPrint.printOrder("purchaseOrderPrint", "purchaseProducts", "purchaseOrder", undefined, record.orderId)}>打印</Button>)
    // },
    // {
    //   title: "操作",
    //   dataIndex: 'operation',
    //   render: (text: any, record: any) =>
    //     <Space size="small">
    //       <Button size={"small"} type="text" onClick={() => survey.order(record.orderId, "VIEW", level, setPurOrderData, setModalPurOrderOpen)}>查看</Button>
    //       <Button disabled={level === 1 ? false : record.orderState !== 99 && record.orderStatus === 1 ? false : true} size={"small"} type="text" onClick={() => survey.order(record.orderId, "EDIT", level, setPurOrderData, setModalPurOrderOpen)}>编辑</Button>
    //     </Space>
    // },
  ]
  // const initData = (values: any, currentEx?: number, pageSizeEx?: number) => {


  //   fetchApi.GET(
  //     wmService,
  //     {
  //       apiId: "entrepotReport",
  //       apiExtend: 'purOrderQuery',
  //       apiData: {
  //         ...values,
  //         pageSize: pageSizeEx ? pageSizeEx : pageSize,
  //         current: currentEx ? currentEx : current
  //       }
  //     }
  //   ).then(res => {

  //     setTotal(res.data.total)
  //     setPageSize(res.data.pageSize)
  //     setCurrent(res.data.current)
  //     setInitValues(values)
  //     setRows(res.data.rows)
  //     setLevel(res.data.level)
  //      showCustomNotification(notificationApi!, {
                                //     type: 'success',
                                //     message:"搜索成功",
                                // description:  "success"
                                // });;

  //   }).catch((err) => {
  //     console.log("err", err);

  //   })
  // }

  // const onClosePurOrderOpen = (status: Boolean) => {
  //   setModalPurOrderOpen(false)
  //   if (status === true) {
  //     initData(initValues)
  //   }
  // }
  const onFinish = () => {
    form.validateFields()
      .then((values: any) => {
        // initData(values)
        setInitValues({
          ...values,
          time: values.chooseTime ? {
            startTime: dayjs(values.chooseTime[0]).format("YYYY-MM-DD"),
            endTime: dayjs(values.chooseTime[1]).format("YYYY-MM-DD"),
          } : null,
          mountDataOptions:{
            userMount: {
                isEmployees: true,
                isDept:true,
                isUserAreas:true
            }
           },
          
        })
      }).catch((err) => {
         showCustomNotification(notificationApi!, {
                                    type: 'warning',
                                    message:"无法通过数据校验",
                                description:  "请输入所需数据!"
                                });;
      })



  }
  // 表格分页属性
  // const paginationProps = {
  //   showSizeChanger: true,
  //   showQuickJumper: false,
  //   showTotal: () => `共${total}条`,
  //   pageSize,
  //   current,
  //   total,
  //   onShowSizeChange: (current: number, pageSize: number) => changePageSize(pageSize, current),
  //   onChange: (current: number) => changePage(current, pageSize),
  // };
  // // 回调函数，切换页数
  // const changePage = (current: number, pageSize: number) => {
  //   if (loading === false) {
  //     initData(initValues, current, pageSize)
  //     loading = true
  //   }
  // }

  // // 回调函数,每页显示多少条
  // const changePageSize = (pageSize: number, current: number) => {
  //   if (loading === false) {
  //     initData(initValues, current, pageSize)
  //     loading = true

  //   }
  // };
  let extendProps: WUExtendPropsType = {
    apiId: 'entrepotReport',
    apiExtend: "purOrderQuery",
    columns: columnsHead,
    pagination: true,
    values:initValues,
    printType:"purchaseOrderPrint",
    typeProducts:"purchaseProducts",
    primaryKeyName:"orderId",
    printApi:"purchaseOrder",
    EditComponent: PurchaseOrderEdit,
  };
  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{chooseTime:[dayjs().subtract(2,'month'), dayjs()]}}
      >
        <Row>

          <Col span={6}>
            <Form.Item label="参数"
              name="parameters"
              rules={[{ required: true, message: '请选择参数!' }]}
            >
              <Select
                // mode="multiple"
                showSearch
                placeholder="参数"
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(aa, 'name')}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="条件"
              name="condition"
              rules={[{ required: true, message: '请选择条件!' }]}
            >
              <Select
                // mode="multiple"
                showSearch
                placeholder="条件"
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(bb, 'name')}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="参数值"
              name="parametersValue"
              rules={[{ required: true, message: '请输入参数值!' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="日期"
              name="chooseTime"
            >
              <RangePicker
              
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>


          <Col span={2}>
            <Form.Item >
              <Space>
                <Button type="primary" htmlType="submit">
                  统计
                </Button >
                {/* <Button onClick={() => DownloadExcel(prop, prop.data)} >
                                  下载EXCEL文件
                              </Button> */}
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>



      {/* <Button onClick={() => DownloadExcel(rows, "moonReport",queryCriteria)}>下载EXCEL文件</Button> */}
      {/* <div style={{ marginTop: "20px" }}>
        <Table
          // scroll={{ y: 750 }}
          pagination={paginationProps}
          rowKey={(record: any, index: any) => index}
          columns={columnsHead}
          dataSource={rows}

        >
        </Table>
        {modalPurOrderOpen ? (<PurchaseOrderEdit data={purOrderData} closeModal={onClosePurOrderOpen}
        />)
          : null}
      </div> */}
        <WebUniversalReport props={extendProps}></WebUniversalReport>
    </>
  )
}

