import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Space, Table } from 'antd';
import dayjs from 'dayjs';

import React, { useEffect, useState } from 'react'
import systemConfig from '../../../../config/systematic';
import fetchApi from '../../../../utils/api/fetchApi';
import getList from '../../../universal/getList';
import antMessage from '../../../../utils/extend/AntdNotification'
import entrepotPrint from '../../../../generalMethod/entrepotPrint';
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { showCustomNotification, useNotificationInstance } from '../../../../utils/extend/AntdNotificationEx';

const { RangePicker } = DatePicker;
export default function EntrepotDailyReport() {

  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  const [form] = Form.useForm()
  const [deptData, setDeptData] = useState<any>();
  const [isCurrentD, setIsCurrentD] = useState<boolean>(false);

  const [orderTypeData, setOrderTypeData] = useState<any>()
  const [buyerData, setBuyerData] = useState<any>()
 
  const [queryCriteria, setQueryCriteria] = useState<any>("")
  const [user, setUser] = useState<any>("")
  const notificationApi = useNotificationInstance()

  // 
  const [rows, setRows] = useState<any>([]);

  const initialValues: any = {
    chooseTime: [dayjs(), dayjs()],
    isBuyerDetail: true
  };


  const columnsHead: Array<any> = [
    {
      title: '来源',
      dataIndex: 'productSources',
      align: "center"
    },
    {
      title: '收货',
      children: [
        {
          title: '金额',
          dataIndex: 'purchaseAmount',
          render: (text: any) => (text ? text.toFixed(2) : "0.00"),
          align: "center"
        },
        {
          title: '张数',
          dataIndex: 'purchaseCount',
          render: (text: any) => (text ? text : 0),
          align: "center"
        }
      ]
    },
    {
      title: '调出',
      children: [
        {
          title: '金额',
          dataIndex: 'exportAmount',
          render: (text: any) => (text ? text.toFixed(2) : "0.00"),
          align: "center"
        },
        {
          title: '张数',
          dataIndex: 'exportCount',
          render: (text: any) => (text ? text : 0),
          align: "center"
        }
      ]

    },
    {
      title: '调入',
      children: [
        {
          title: '金额',
          dataIndex: 'importAmount',
          render: (text: any) => (text ? text.toFixed(2) : "0.00"),
          align: "center"
        },
        {
          title: '张数',
          dataIndex: 'importCount',
          render: (text: any) => (text ? text : 0),
          align: "center"

        }
      ]
    },
    // {
    //   title: '张数(调入)',
    //   dataIndex: 'exportCount',
    //   render: (text: any) => (text ? text: 0)

    // },
    // {
    //   title: '损溢',
    //   dataIndex: 'changed',
    // },

  ];
  useEffect(() => {

    const fetchData = async () => {
      form.setFieldValue("chooseTime", [dayjs(), dayjs()])
      let deptData = await fetchApi.GET(wmService, {
        apiId: "dept",
        apiExtend: 'showList',
        apiData:{
                    
        }
      })
      if (deptData.success) {
        setDeptData(deptData.data)
      }
      let orderTypeData = await fetchApi.GET(wmService, {
        apiId: "entrepot",
        apiExtend: 'getOrderType'
      })
      if (orderTypeData.success) {

        setOrderTypeData(orderTypeData.data)
      }
      let buyerData = await fetchApi.GET(wmService, {
        apiId: "buyer",
        apiExtend: 'showList'
      })



      if (buyerData.success) {

        setBuyerData(buyerData.data)
      }

      // 
    }
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const onFinish = () => {
    form.validateFields()
      .then(async (values: any) => {


        fetchApi.GET(wmService, {
          apiId: "entrepotReport",
          apiExtend: 'dailyReportEx',
          apiData: {
            deptIds: handleData(values.deptIds, "deptId"),
            orderTypes: handleData(values.orderTypes, "orderType"),
            isBuyerDetail: values.isBuyerDetail ? values.isBuyerDetail : false,
            time: values.chooseTime ? {
              startTime: dayjs(values.chooseTime[0]).format("YYYY-MM-DD"),
              endTime: dayjs(values.chooseTime[1]).format("YYYY-MM-DD"),
            } : null,
            buyers: handleData(values.buyers, "buyerId"),
            selfs: values.selfStart && values.selfEnd ? {
              selfStart: values.selfStart,
              selfEnd: values.selfEnd
            } : null,
            isCurrentBranchStore: values.isCurrentBranchStore,
            mountDataOptions:{
              userMount: {
                  isEmployees: true,
                  isDept:true,
                  isUserAreas:true
              }
             },
          }
        }).then((res: any) => {

          setRows(res.data.rows)
          setQueryCriteria(res.data.queryCriteria)
          setUser(res.data.user)
           showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message:"搜索成功",
                                description:  "success"
                                });;

        }).catch((err: any) => {
          console.log("err", err);

          antMessage('error', '搜索失败', err.message + ' | status：' + err.errors.status);
        })
      })
      .catch((err) => {
         showCustomNotification(notificationApi!, {
                                    type: 'warning',
                                    message:"无法通过数据校验",
                                description:  "请输入所需数据!"
                                });;
      })
  }
  const aa=(e:any)=>{
    
       setIsCurrentD(e.target.checked)
    
  }
  const onChangeBranchStore=(e:any)=>{
    let checked=e.target.checked;
    
    if(checked){
      fetchApi.GET(wmService, {
        apiId: "branchStore",
        apiExtend: 'getDeptByentrepot',
       
      }).then((res: any) => {
        form.setFieldValue("deptIds",res.data)
      }).catch((err: any) => {
          form.setFieldValue("deptIds",[])
            console.log("err",err);
            
        
      })
    }else{
      form.setFieldValue("deptIds",[])
    }
     
  }
  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col span={16}>
            <Form.Item
              label="部门"
              name="deptIds"
            >
              <Select
                // orderTypeData
                mode="multiple"
                showSearch
                placeholder="请选择部门!"
                optionFilterProp="children"

                onSearch={(e) => getList.search('dept', setDeptData, e)}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(deptData, 'deptName')}
              </Select>
            </Form.Item>
          </Col>
        
          <Col span={6}>
            <Form.Item
              label="订单类型"
              name="orderTypes"
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="订单类型"
                optionFilterProp="children"
                // onSearch={(e) => getList.search('dept', setDeptData, e)}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(orderTypeData, 'orderTypeName', "orderType")}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="采购商"
              name="buyers"
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="采购商"
                optionFilterProp="children"
                onSearch={(e) => getList.search('buyer', setBuyerData, e)}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(buyerData, 'buyerName')}
              </Select>
            </Form.Item>
          </Col>

          <Col span={1.5}>
            <Form.Item
              label="采购商明细"
              name="isBuyerDetail"
              valuePropName="checked"
            >
              <Checkbox></Checkbox>
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item
              label="品号"
              name="selfStart"
            >
              <Input
              // onChange={handleChange}
              // onBlur={handleBlur}
              // placeholder="Input a number"
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item
              colon={false}
              label="-"
              name="selfEnd"
            >
              <Input
              // onChange={handleChange}
              // onBlur={handleBlur}
              // placeholder="Input a number"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="日期"
              name="chooseTime"
              
            >
              <RangePicker
               locale={locale}
                defaultValue={initialValues.chooseTime}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>

          <Col span={1.5}>
            <Form.Item
              label="当前分店"
              name="isCurrentBranchStore"
              valuePropName="checked"
               
            >
              <Checkbox onChange={onChangeBranchStore}></Checkbox>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item >
              <Space>
                <Button type="primary" htmlType="submit">
                  统计
                </Button >
                <Button onClick={() => entrepotPrint.printEnReport(rows, "entrepotDailyReportPrint", queryCriteria, user)} >
                  打印
                </Button>

              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>



      {/* <Button onClick={() => DownloadExcel(rows, "moonReport",queryCriteria)}>下载EXCEL文件</Button> */}
      <div style={{ marginTop: "20px" }}>
        <Table
          pagination={false}
          rowKey={(record: any) => record.id}
          columns={columnsHead}
          dataSource={rows}
        // summary={(pageData: any) => {
        //   let totalExport = 0;
        //   let totalExportCount = 0;

        //   let totalImport = 0;
        //   let totalImportCount = 0;

        //   let totalPurchase = 0;
        //   let totalPurchaseCount = 0;

        //   if (pageData.length > 0) {
        //     pageData.map((item: any) => {
        //       if (item.export) totalExport += item.export
        //       if (item.import) totalImport += item.import;
        //       if (item.purchase) totalPurchase += item.purchase;

        //       if (item.exportCount) totalExportCount += item.exportCount;
        //       if (item.importCount) totalImportCount += item.importCount;
        //       if (item.purchaseCount) totalPurchaseCount += item.purchaseCount;

        //     })
        //   }
        //   return (
        //     <Table.Summary  fixed>
        //       <Table.Summary.Row style={{"textAlign":"center"}}>
        //         <Table.Summary.Cell  index={0}>合计</Table.Summary.Cell>
        //         <Table.Summary.Cell  index={1}>{totalPurchase.toFixed(2)}</Table.Summary.Cell>
        //         <Table.Summary.Cell index={2}>{totalPurchaseCount}</Table.Summary.Cell>

        //         <Table.Summary.Cell index={3}>{totalImport.toFixed(2)}</Table.Summary.Cell>
        //         <Table.Summary.Cell index={4}>{totalImportCount}</Table.Summary.Cell>



        //         <Table.Summary.Cell index={5}>{totalExport.toFixed(2)}</Table.Summary.Cell>
        //         <Table.Summary.Cell index={6}>{totalExportCount}</Table.Summary.Cell>

        //       </Table.Summary.Row>
        //     </Table.Summary>
        //   )
        // }}
        >
        </Table>
      </div>
    </>
  )
}
export let handleData = (rows: any, objKey: string) => {
  let newArr: Array<any> = []

  if (typeof rows === "undefined") return newArr

  for (var i = 0; i < rows.length; i++) {
    newArr.push({
      [objKey]: rows[i]
    })
  }

  return newArr
}