import React, { useEffect, useRef } from 'react'
import { columnsheadId } from '../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../system/WebUniversalNewEx';
import DeptProdcutTimeEdit from './DeptProductTimeEdit';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import { Switch } from 'antd';
import fetchApi from '../../utils/api/fetchApi';
import systemConfig from '../../config/systematic';
import antMessage from "../../utils/extend/AntdNotification"
import { InitData } from '../../store/system/showPage';
import { showCustomNotification, useNotificationInstance } from '../../utils/extend/AntdNotificationEx';

export default function DeptProductTime(props: any) {
    const ref = useRef<any>(undefined);
    const { closeModal, data } = props;
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const notificationApi = useNotificationInstance()

     /** 刷新数据 */
     let initData: any;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // bmService = ref.current.bmService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        //同步获取数据 async - await
        // const fetchData = async () => {
        // }
        // fetchData();
      
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据
    //表头
    const columnsHead = [
        {
            title: '星期一',
            dataIndex: 'Monday',
            editable: true,
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'Monday', e)}
                />
            )
        },
        {
            title: '星期二',
            dataIndex: 'Tuesday',
            editable: true,
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'Tuesday', e)}
                />
            )
        },
        {
            title: '星期三',
            dataIndex: 'Wednesday',
            editable: true,
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'Wednesday', e)}
                />
            )
        },
        {
            title: '星期四',
            dataIndex: 'Thursday',
            editable: true,
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'Thursday', e)}
                />
            )
        },
        {
            title: '星期五',
            dataIndex: 'Friday',
            editable: true,
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'Friday', e)}
                />
            )
        },
        {
            title: '星期六',
            dataIndex: 'Saturday',
            editable: true,
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'Saturday', e)}
                />
            )
        },
        {
            title: '星期日',
            dataIndex: 'Sunday',
            editable: true,
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'Sunday', e)}
                />
            )
        },

    ];
    //改变数据
    const handleChange = async (id: any, columnName: string, isValue: boolean) => {

        const bodys = {
            apiId: "deptProductTime",
            apiVariable: id,
            apiExtend: "update",
            apiData: {
                columnName,
                isValue
            }
        }
        fetchApi.PUT(wmService, bodys).then(res => {
            initData()
             showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message: '编辑成功',
                                    description: 'success',
                                });;

        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '部门产品下单时间',
        apiId: 'deptProductTime',
        showStates: false,
        columns,
        pagination: true,
        showAdd: false,
        showOperation: false,
        // EditComponent: DeptProdcutTimeEdit,
        sendPageData: { productId: data.productId, deptId: data.deptId }
    };
    const handleOk = (e: any) => {
        closeModal(false);
    };
    const handleCancel = (e: any) => {
        closeModal(false);
    };
    return (
        <>
            <AntDraggableModal
                title={'部门产品下单时间'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={900}>
                <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            </AntDraggableModal>
        </>

    )
}
