import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType, initDataType } from '../../system/WebUniversalNewEx';
import MenuWarehouseEdit from './MenuWarehouseEdit';
import MenuWarehouseIngredient from './MenuWarehouseIngredient';
// import CopyMenuWarehouse from './CopyMenuWarehouse';
import MenuWarehouseSpec from './MenuWarehouseSpec';
import MenuMovie from './MenuMovie';
import MenuWarehouseUploadFile from './MenuWarehouseUploadFile';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal, Space, Timeline, Tooltip } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification';
import { SocketContext } from '../../../App';
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';

export default function MenuWarehouse(props: any) {

    const ref = useRef<any>(undefined);
    const apiId = "menuWarehouse";
    const notificationApi = useNotificationInstance()

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    //规格窗口
    const [menuWarehouseSpecVisible, setMenuWarehouseSpecVisible] = useState(false);

    //视频窗口
    const [menuMovieVisible, setMenuMovieVisible] = useState(false);

    //历程窗口
    const [historyVisible, setHistoryVisible] = useState(false);

    //历程窗口
    const [historyData, setHistoryData] = useState([
        {
            children: '1',
            label: '啫红三鱼'
        },
        {
            children: '2',
            color: 'red',
            label: '啫鱼头'
        },
        {
            children: '3',
            label: '啫膏蟹'
        },
    ]);

    //主配料窗口
    const [menuWarehouseIngredientVisible, setMenuWarehouseIngredientVisible] = useState(false);

    // //复制菜式窗口
    // const [copyMenuWarehouseVisible, setCopyMenuWarehouseVisible] = useState(false);

    // //复制菜式数据
    // const [copyMenuWarehouseData, setCopyMenuWarehouseData] = useState<any>();

    //规格数据
    const [specData, setSpecData] = useState<any>();

    //仓库ID
    const [menuWarehouseId, setMenuWarehouseId] = useState<any>();

    //主配料数据
    const [ingredientData, setIngredientData] = useState<any>();

    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;

    useEffect(() => {
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //传关参数
    let sendData = {
        type: 'EDIT',
        id: '',
        foodName: '',
        path: 'MenuWarehouse',
        key: '',
        brandId: ''
    }

    const initData = async () => {
        // const menuHeadId = data.menuHeadId;
        const currentData: initDataType = {
            apiId,
            // sendPageData: { menuHeadId: menuHeadId }
        }
        ref.current.initData(currentData);
    }

    //关闭仓库菜式规格页面
    const onCloseMenuWarehouseSpec = async (status: boolean) => {
        setMenuWarehouseSpecVisible(false);
        if (status) {
            await initData();
        }
    }

    //打开视频窗口
    const handleMenuMovie = (record: any) => {
        //console.log(record)
        setMenuWarehouseId(record.id);
        setMenuMovieVisible(true);
    };

    //关闭视频页面
    const onCloseMenuMovie = async (status: boolean) => {
        setMenuMovieVisible(false);
        // if (status) {
        //     await initData();
        // }
    }

    //打开规格窗口
    const handleMenuWarehouseSpec = (record: any) => {
        //console.log(record)
        sendData.id = record.id;
        sendData.foodName = record.foodName;
        setSpecData(sendData);
        setMenuWarehouseSpecVisible(true);
    };

    //打开历程窗口
    const handleHistory = (record: any) => {
        fetchApi.GET(wmService, {
            apiId: "menuWarehouse",
            apiExtend: 'checkHistory',
            apiData: {
                data: record,
                id: record.id,
                superiorMenuWarehouseId: record.superiorMenuWarehouseId,
                version: record.version
            }
        }).then(res => {
            if (res.success) {
                let newDatas: Array<any> = [];
                for (var j = 0; j < res.data.length; j++) {
                    newDatas.push({
                        children: (<>
                            <p>{res.data[j].foodName}</p>
                            <p>{res.data[j].created ? res.data[j].created + "年创于" + res.data[j].area.areaName : ""}</p>
                        </>)
                        ,
                        label: res.data[j].version,
                        color: record.id === res.data[j].id ? 'red' : 'blue'
                    })
                }
                setHistoryData(newDatas);
            }
        });

        setHistoryVisible(true);
    };

    //关闭历程窗口
    const onCloseHistory = () => {
        setHistoryVisible(false);
    }

    //改变数据
    const handleChange = async (id: any, columnName: string, value: boolean) => {
        const bodys = {
            apiId: "menuWarehouse",
            apiVariable: id,
            apiExtend: "updateOne",
            apiData: {
                columnName,
                value
            }
        }

        fetchApi.PUT(wmService, bodys).then(res => {
             showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message: '编辑成功',
                                    description: 'success',
                                });;
            initData();
        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };

    //打开添加主配料
    const handleIngredient = (record: any) => {
        //console.log(record)
        sendData.id = record.id;
        sendData.foodName = record.foodName;
        setIngredientData(sendData);
        setMenuWarehouseIngredientVisible(true);
    };

    //表头
    const columnsHead = [
        {
            title: '品牌',
            dataIndex: 'brandName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.brand.brandName}</div>
                )
            }
        },
        {
            title: '大分类',
            dataIndex: 'menuWarehouseCategory',
            render: (text: any, record: any) => {
                return (
                    <div>{record.menuWarehouseType.menuWarehouseCategory.menuWarehouseCategoryName}</div>
                )
            }
        },
        {
            title: '小分类',
            dataIndex: 'menuWarehouseType',
            render: (text: any, record: any) => {
                return (
                    <div>{text.menuWarehouseTypeName}</div>
                )
            }
        },
        {
            title: '烹调方法',
            dataIndex: 'cookMethod',
            render: (text: any, record: any) => {
                return (
                    <div>{text.cookMethodName}</div>
                )
            }
        },
        {
            title: '菜式名称',
            dataIndex: 'foodName',
            render: (text: any, record: any) => {
                return (
                    <Tooltip placement="topLeft" title={record.foodNameEn ? record.foodNameEn : ""}>
                        <div>{text}</div>
                    </Tooltip>
                )
            }
        },
        {
            title: '创作时间地区',
            dataIndex: 'created',
            render: (text: any, record: any) => {
                if (text) {
                    return (
                        <div>{text + "年创于" + record.area.areaName}</div>
                    )
                }
            }
        },
        {
            title: "历程",
            dataIndex: "history",
            render: (text: any, record: any) => {
                return (
                    <Button size={"small"} type="primary" onClick={() => handleHistory(record)}>历程</Button>
                )
            }
        },
        {
            title: '视频',
            dataIndex: 'movie',
            key: 'movie',
            render: (text: any, record: any) => {
                return (
                    <Space size="small">
                        <Button size={"small"} type="primary" onClick={() => handleMenuMovie(record)}>视频</Button>
                    </Space>
                )
            }
        },
        {
            title: '规格',
            dataIndex: 'menuWarehouseSpec',
            key: 'menuWarehouseSpec',
            render: (text: any, record: any) => {
                if (record.menuWarehouseSpecs.length > 0) {
                    return (
                        <Space size="small">
                            <Button size={"small"} type="primary" onClick={() => handleMenuWarehouseSpec(record)}>规格</Button>
                        </Space>
                    )
                }
                else {
                    return (
                        <Space size="small">
                            <Button size={"small"} type="primary" danger onClick={() => handleMenuWarehouseSpec(record)}>规格</Button>
                        </Space>
                    )
                }
            }
        },
        {
            title: '主配料',
            dataIndex: 'ingredient',
            key: 'ingredient',
            render: (text: any, record: any) => {
                if (record.menuWarehouseSpecs.length > 0) {
                    return (
                        <Space size="small">
                            <Button size={"small"} type="primary" onClick={() => handleIngredient(record)}>主配料</Button>
                        </Space>
                    )
                }
            }
        },
        // {
        //     title: '复制',
        //     dataIndex: 'copy',
        //     key: 'copy',
        //     render: (text: any, record: any) => {
        //         if (record.menuWarehouseSpecs.length > 0) {
        //             return (
        //                 <Tooltip placement="topLeft" title={'复制【' + record.foodName + '】到其他品牌'}>
        //                     <Space size="small">
        //                         <Button size={"small"} type="primary" onClick={() => handleCopy(record, 'single')}>复制</Button>
        //                     </Space>
        //                 </Tooltip>
        //             )
        //         }
        //     }
        // },
        // {
        //     title: '排序',
        //     dataIndex: 'sortBy',
        // },
    ];


    // /**
    //  * 复制菜式
    //  * @param id 
    //  */

    // const handleCopy = (record: any, key: any) => {

    //     if (key === 'single') {
    //         sendData.id = record.id;
    //         sendData.foodName = record.foodName;
    //         sendData.brandId = record.brand.id;
    //     }
    //     sendData.key = key;
    //     setCopyMenuWarehouseData(sendData);
    //     setCopyMenuWarehouseVisible(true);

    //     // fetchApi.POST(wmService, {
    //     //     apiId: 'menuWarehouse',
    //     //     apiExtend: 'copy',
    //     //     apiVariable: record.id
    //     // }).then(res => {
    //     //     console.log(res.data);
    //     // })
    // };

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式仓库',
        apiId,
        showImage: true,
        showUploadFile: true,
        showStates: false,
        disabledAdd: false,
        showOperation: true,
        showAdd: true,
        pagination: true,
        columns,
        EditComponent: MenuWarehouseEdit,
        arrayFilesName: 'menuWarehouseFiles',
        UploadFileComponent: MenuWarehouseUploadFile,
        sendPageData: {
            // branchStoreId: localStorage.getItem("currentBranchStoreId")
            branchStoreId: contextData?.currentBranchStoreId
        },
        mountDataOptions: {
            userMount: {
                isBranchStore: true
            }
        }
    };

    return (
        <>
            <Modal
                title="菜式历程"
                open={historyVisible}
                okText='关闭'
                onOk={onCloseHistory}
                onCancel={onCloseHistory}
                cancelButtonProps={{ style: { display: "none" } }}
                style={{ fontSize: '20px' }}
                width={500}
            >
                <br></br>
                <br></br>
                <br></br>
                <Timeline mode='left' items={historyData} />
            </Modal>

            {/* <Popconfirm
                title="批量复制菜式到其他品牌"
                description="考虑中。。。。。。。。。。。。。。。。。"
            // onConfirm={() => handleCopy(undefined, 'all')}
            >
                <Button type="primary">复制菜式到其他品牌</Button>
            </Popconfirm> */}
            {/* <Button type="primary" onClick={() => handleCopy(undefined, 'all')}>复制菜式到其他品牌</Button> */}
            {/* <span>&nbsp;&nbsp;</span> */}

            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>

            {menuWarehouseIngredientVisible
                ? (<MenuWarehouseIngredient
                    closeModal={setMenuWarehouseIngredientVisible} data={ingredientData} />)
                : null}

            {menuWarehouseSpecVisible
                ? (<MenuWarehouseSpec
                    closeModal={onCloseMenuWarehouseSpec} data={specData} />)
                : null}

            {menuMovieVisible
                ? (<MenuMovie
                    closeModal={onCloseMenuMovie} menuWarehouseId={menuWarehouseId} isModel={true} />)
                : null}

            {/* {copyMenuWarehouseVisible
                ? (<CopyMenuWarehouse
                    closeModal={setCopyMenuWarehouseVisible} data={copyMenuWarehouseData} />)
                : null} */}
        </>
    )
}