import AreaEdit from '../AreaEdit';
import WebUniversal, { WUExtendPropsType } from '../../../system/WebUniversalNewEx'
import AntDraggableModal from '../../../../utils/extend/AntDraggableModal';
import { useState } from 'react';
import { Form, Input } from 'antd';
import fetchApi from '../../../../utils/api/fetchApi';
import systemConfig from '../../../../config/systematic';
import antMessage from '../../../../utils/extend/AntdNotification'
import { showCustomNotification, useNotificationInstance } from '../../../../utils/extend/AntdNotificationEx';

export default function DutyConfirmation(props:any) {
    
     const [modalVisible, setModalVisible] = useState(true);
     const [form] = Form.useForm(); //form 
   /** 后端数据wmService */
   let wmService: any = systemConfig.wmService;
   
   const notificationApi = useNotificationInstance()
 
      /** 确定 */
      const handleOk = async (e: any) => {
        form.validateFields() //数据校验
        .then(async (values: any) => {             
            fetchApi.POST(wmService, {
                apiId: "beOnDuty",
                apiExtend: "dutyConfirmation",
                apiData:{
                    ...values,
                    mountDataOptions:{
                        userMount: {
                            isDept: true
                          }
                    }  
                }
            }).then(res => {
                   setModalVisible(false)
                // setLoading(false)
                antMessage('success', '确认成功', 'success');
            }).catch(err => {
                // setLoading(false)
                 showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message:"错误，无法保存",
                                    description:  err.message + ' | status：' + err.errors.status,
                                });
            })
        })
        .catch((err: any) => {

            console.log("err", err);

             showCustomNotification(notificationApi!, {
                                    type: 'warning',
                                    message:"无法通过数据校验",
                                description:  "请输入所需数据!"
                                });;
        });
        // setModalVisible(false)
    };

    
      /** 确定 */
      const handleCancel = async (e: any) => {
        setModalVisible(false)
    };

 
 
     return (
        <>
        <AntDraggableModal
            title={'值班确认'}
            open={modalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            
            closable={false}
            maskClosable={true}
            width={300}>
            <div id='main'>
                <Form
                    form={form}
                    name="Edit">
                    
                    <Form.Item
                        label="预支款数"
                        name="advancePayment">
                        <Input />
                    </Form.Item>
                </Form>
            </div>
        </AntDraggableModal>
    </>
     )
 }

