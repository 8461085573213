import React, { useState, useEffect } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import { Button, Col, Form, Row, Switch } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import SendMessage from '../messageManage/SendMessage';
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';

export default function CompleteRoom(props: any) {
    const { closeModal, customerReserveId } = props;

    const [form] = Form.useForm(); //form 
    /**打开信息发送窗口 */
    const notificationApi = useNotificationInstance()

    const [modalSendMessagelopen, setModalSendMessageopen] = useState<Boolean>(false)
    //发送信息窗口数据
    const [sendMessagelData, setSendMessagelData] = useState<any>()
    let wmService: any = systemConfig.wmService;
    const handleOk = (e: any) => {
        form.validateFields()
            .then(async (values: any) => {

                // setModalSendMessageopen(true)
                // setSendMessagelData({
                //     customerReserveId
                // })
                const { isInvitation, isSend } = values;
                fetchApi.POST(wmService, {
                    apiId: "customerReserve",
                    apiExtend: 'upDateCustomerReserve',
                    apiData: {
                        customerReserveId,
                        isInvitation
                    }
                }).then(res => {
                    if (isSend) {
                        setModalSendMessageopen(true)
                        setSendMessagelData({
                            customerReserveId
                        })

                    } else {
                        closeModal(true)
                    }
                }).catch(err => {
                    console.log("err", err);

                })


            }).catch((err: any) => {
                console.log(err);
                showCustomNotification(notificationApi!, {
                    type: 'warning',
                    message: "无法通过数据校验",
                    description: "请输入所需数据!"
                });
            })

    };
    useEffect(() => {

    }, [])
    const handleCancel = (e: any) => {
        closeModal(false)

    };
    const closeSendMessageopen = (state: boolean) => {

        setModalSendMessageopen(false)
        // if (state === true) {
        closeModal(true)
        // }
    }


    return (
        <>

            <AntDraggableModal
                title={'配置'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row >
                        <Col span={12}>
                            <Form.Item
                                label="发送信息"
                                name="isSend"
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Switch
                                    checked={true}
                                    checkedChildren="是" unCheckedChildren="否"
                                ></Switch>
                            </Form.Item>
                        </Col>


                        <Col span={12}>
                            <Form.Item
                                label="生成邀请函"
                                name="isInvitation"
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Switch
                                    checked={true}
                                    checkedChildren="是" unCheckedChildren="否"
                                ></Switch>
                            </Form.Item>
                        </Col>


                    </Row>
                </Form>
                {/**发送信息 */}
                {modalSendMessagelopen ? (<SendMessage data={sendMessagelData} closeModal={closeSendMessageopen} />)
                    : null}
            </AntDraggableModal>

        </>
    )
}
