import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Switch, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import systematic from '../../../config/systematic';

const PrintConfigurationEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;

    
    const { sendPageData } = data;
    
   const {branchStoreId,deptId}=sendPageData

    /**打印单类型数据 */
    const [printTypeData, setPrintTypeData] = useState<Array<any>>([]);
    /**打印机数据 */
    const [printData, setPrintData] = useState<Array<any>>([]);

    /**楼层数据 */
     const [branchStoreFloor,setBranchStoreFloor]=useState<Array<any>>([])
    /**部门数据 */
    // const [deptData, setDeptData] = useState<Array<any>>([]);

    const [roomTypeData, setRoomTypeData] = useState<any>();
    const ref = useRef<any>(undefined);
    /** 后端数据wmService */
    let wmService: any = systematic.wmService;
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;

        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: "printOrderType",
            }).then(res => {
                if (res.success) {
                    setPrintTypeData(res.data)
                }
            })

            fetchApi.GET(wmService, {
                apiId: "branchStoreFloor",
                apiExtend: 'showList',
                apiData:{
                    mountDataOptions: {
                        userMount: {
                            isEmployees: true,
                            isDept:true
                        }
                    },
                    sendPageData:{
                        branchStoreId
                    }
                }
            }).then(res => {
                
                if (res.success) {
                    setBranchStoreFloor(res.data)
                }
            })

            fetchApi.GET(wmService, {
                apiId: "printer",
                apiExtend: 'showList',
                apiData: {
                    branchStoreId,
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                        }
                    },
                }
            }).then(res => {
                if (res.success) {

                    setPrintData(res.data)
                }
            })

            fetchApi.GET(wmService, {
                apiId: "roomType",
                apiExtend: 'showList',
                apiData: {
                    branchStoreId,
                    mountDataOptions: {
                        userMount: {
                            isDept:true
                        }
                    },
                    extendObj:{
                        menuHeadsIdNum:-1,

                    }
                }
            }).then(res => {
                if (res.success) {

                    setRoomTypeData(res.data)
                }
            })
            /**获取房间类型 */
            // if (!sendPageData.deptId) {
            //     fetchApi.GET(wmService, {
            //         apiId: "dept",
            //         apiExtend: 'showList',
            //     }).then(res => {
            //         if (res.success) {

            //             setDeptData(res.data)
            //         }
            //     })
            // }
        }

        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'printConfiguration',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '打印机'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>
                    <Row >
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="打印单类型"
                                name="printType"
                                rules={[{ required: true, message: '请选择打印单类型!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="打印类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(printTypeData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="打印机"
                                name="printerId"
                                rules={[{ required: true, message: '请选择打印机!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="打印机"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(printData, 'printerName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="分店楼层"
                                name="branchStoreFloorId"
                               
                            >
                                <Select
                                    showSearch
                                    placeholder="分店楼层"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(branchStoreFloor, 'floor',undefined,undefined,true)}
                                </Select>
                            </Form.Item>
                        </Col>
                        {deptId? <Col span={8}>
                            <Form.Item
                                label="房间类型"
                                name="roomTypeId"
                                >
                                <Select
                                  allowClear
                                    showSearch
                                    placeholder="选择房间类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(roomTypeData, 'roomType',undefined,undefined,true)}
                                </Select>
                            </Form.Item>
                        </Col>:null}
                       
                        {/* {
                            sendPageData?.deptId ? null : <Col span={12}>
                                <Form.Item
                                    label="部门"
                                    name="deptId"
                                >
                                    <Select
                                        showSearch
                                        placeholder="部门"
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {getList.general(deptData, 'deptName')}
                                    </Select>
                                </Form.Item>
                            </Col>
                        } */}



                        {/* 
                        <Col span={12}>
                            <Form.Item
                                label="房台"
                                name="roomId"
                             
                                >
                                <Select
                                    showSearch
                                    placeholder="打印机"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(printData, 'windowPrinterName')}
                                </Select>
                            </Form.Item>
                        </Col> */}

                        <Col span={8}>
                            <Form.Item
                                label="打印页数"
                                name="copies"

                            >
                                <InputNumber defaultValue={1} min={1} />
                            </Form.Item>
                        </Col>


                        <Col span={8}>
                            <Form.Item
                                label="静默打印"
                                name="isSilent"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="显示打印页设置"
                                name="isPrinterPage"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>


                        <Col span={8}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>


                    </Row>









                </Form>
            </AntDraggableModal>
        </>
    )
}

export default PrintConfigurationEdit;