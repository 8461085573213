import { notification } from 'antd';

/** 泡泡类型 */
export declare type antdNotificationType = 'success' | 'error' | 'info' | 'warning' | 'open';

/**
* 右侧提示泡泡
* @param type 类型 'success', 'error','info','warning','open'
* @param message 消息
* @param description 描述
* @param configuration 参数配置
*/
const AntdNotification = (type: antdNotificationType, message: string, description?: string, configuration?: any) => {
    let duration = configuration?.duration ? configuration?.duration : 2;
    let placement = configuration?.placement ? configuration?.placement : "topRight"
    
    switch (type) {
        case 'success':
            notification.success({
                message,
                description: JSON.stringify(description),
                duration,
                placement,
            });
            break;

        case 'error':
            
            notification.error({
                message,
                description: JSON.stringify(description),
                duration,
                placement
            });
            break;

        case 'info':
            notification.info({
                message,
                description: JSON.stringify(description),
                duration,
                placement
            });
            break;

        case 'warning':
            notification.warning({
                message,
                description: JSON.stringify(description),
                duration: 2,
                placement
            });
            break;


        case 'open':
            notification.open({
                message,
                description: JSON.stringify(description),
                duration: 2
            });
            break;
    }
}

export default AntdNotification;

