import { Table, Button, Space, Input, Tooltip, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { connect } from 'react-redux';
import { InitData } from '../../store/system/showPage';
import fetchApi from '../../utils/api/fetchApi'
import antMessage from '../../utils/extend/AntdNotification'
import WebBehaviors, { behaviorsDataType, deleteButtonConfigType } from './behaviors/WebBehaviorsNew';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import system from '../../config/systematic';
import moment from 'moment';
import { showCustomNotification, useNotificationInstance } from '../../utils/extend/AntdNotificationEx';
/** serviceIdType */
// export declare type serviceIdType = 'bm' | 'pay' | 'message';

// export const getService = (serviceId: string) => {
//     /** 默认service */
//     var defaultService = systematic.bmService
//     if (serviceId) {
//         switch (serviceId) {
//             case 'pay':
//                 defaultService = systematic.payService
//                 break;
//             case 'message':
//                 defaultService = systematic.messageService
//                 break;
//         }
//     }
//     return defaultService;
// };
/** WebUniversal ExtendProps数据类型 */
export declare type WUExtendPropsType = {
    /** 图片数据数组名称 */
    arrayFilesName?: string,
    /**是否需要交预定金 */
    showDeposit?: boolean,
    /**是否显示发送选项 */
    showSend?: boolean,
    /**是否默认 */
    showDefault: boolean,
    /**是否自动生成 */
    showAutomatic?: boolean,
    /** 编辑组件 */
    EditComponent?: any,
    /**插入组件 */
    InsertComponent?: any
    /**批量更新组件 */
    UnDateComponent?: any
    /** 文件上传组件 */
    UploadFileComponent?: any,
    /** 标题 */
    titleName: string,
    /** api服务id */
    apiId: string,
    /** 主键名称 */
    primaryKeyName?: string,
    /** 是否显示文件上传按键 */
    showUploadFile?: boolean,
    /** 是否显示增加键 */
    showAdd?: boolean,
    /** 是否显示查询 */
    showQuery?: boolean,
    /** 查询的提示信息 */
    queryTitle?: string,
    /** 是否显示图片 */
    showImage?: boolean,
    /** 是否显示状态 */
    showStates?: boolean,
    /** 是否显示预定状态*/
    showReserve?: boolean,
    /** 是否显示操作区域 */
    showOperation?: boolean,
    /** 是否显示删除键 */
    showDelete?: boolean,
    /** 是否显示编辑键 */
    showEdit?: boolean,
    /** 是否显示打印键 */
    showPrint?: boolean,
    /** 显示日期*/
    showDate?: boolean
    /** 是否禁用增加键 */
    disabledAdd?: boolean,
    /** 是否禁用上传键 */
    disabledUpload?: boolean,
    /** 表头 */
    columns: Array<any>,
    /** 扩展参数 */
    extendedParameter: any,
    /** 页面传送其它数据 */
    sendPageData?: any,
    /** 是否有子表格？
     * （表格内容较多，如参数expandableDataName：undefined时，可分两行展示）
     */
    isExpandable?: boolean,
    /**是否有子子表格 */
    // isIsExpandable?: boolean,
    /** 子子表格表头 */
    // isExpandableColumns?: Array<any>,
    /** 子表格表头 */
    expandableColumns?: Array<any>,
    /** 子子表格数据数组对象名称，当为undefined时，如表格内容较多，可分两行展示 */
    // isExpandableDataName?: any
    /** 子表格数据数组对象名称，当为undefined时，如表格内容较多，可分两行展示 */
    expandableDataName?: any,
    /** 总结栏 */
    summaryRender?: any,
    /** 是否展开所有数据栏 */
    // isExpandAllRows?: boolean,
    /** 删除按钮配置 */
    deleteButtonConfig?: deleteButtonConfigType,
    /** 删除按钮中的checkBox为true时执行方法(handleDelete执行之前执行) */
    beforeHandleDeleteCheckBox?: Function,
    /** 删除按钮中的checkBox为true时执行方法(handleDelete执行成功后才执行) */
    afterHandleDeleteCheckBox?: Function,
    /** 覆盖删除方法 */
    coverHandleDelete?: Function,

    /**树形结构缩进 */
    indentSize: number,
    pagination: boolean,
    extendIdName?: string,
    /**树形孩子名称 */
    childrenColumnName?: string
};

/** 刷新数据的类型 */
export declare type initDataType = {
    /** 服务id */
    apiId?: string,
    /** 页面传送的数据 */
    sendPageData?: any
};

//Redux-React
const mapStateToProps = (state: {}) => {
    return {
        ...state,
    };
};
const mapDispatchToProps = { InitData }

/**
 * 通用Web组件 WebUniversal 
 * @param props 参数对象
 * @param ref 返回的对象
 * @returns 
 */
let WebUniversal = (props: any, ref: any) => {
    let extendProps: WUExtendPropsType;
    extendProps = props.props;

    const {
        arrayFilesName,
        EditComponent,

        UploadFileComponent,
        titleName,
        apiId,
        primaryKeyName,
        showUploadFile,
        showAdd,
        showQuery,
        queryTitle,
        showImage,
        showStates,
        showReserve,
        showOperation,
        showDelete,
        showEdit,
        showPrint,
        disabledAdd,
        disabledUpload,
        sendPageData,
        extendedParameter,
        summaryRender,
        pagination,
        showDeposit,
        showAutomatic,
        showDefault,
        showSend,
        // isExpandAllRows,
        showDate,
        deleteButtonConfig,
        beforeHandleDeleteCheckBox,
        afterHandleDeleteCheckBox,
        coverHandleDelete,
        indentSize,
        extendIdName,
        childrenColumnName
    } = extendProps;

    /** 后端数据wmService */
    const wmService = system.wmService;











    // 参数
    const { InitData, showPageData } = props;
    /** 初始值 */
    let data: any = {
        pageSize: 10,
        current: 1,
        queryString: undefined,
        total: 0,
        rows: undefined,
        extendData: {}
    };

    try {
        if (typeof showPageData[apiId] !== "undefined") {

            data = showPageData[apiId].data;
        }
    } catch { }


    let { pageSize,
        current,
        total } = data;
    /** 数据集合 */
    let rows = data.rows;
     


    const [modalopen, setModalopen] = useState(false);
    const [insertModalopen, setInsertModalopen] = useState(false);
    const [updataModalopen, setUpdataModalopen] = useState(false);
    const [modalUploadopen, setModalUploadopen] = useState(false);
    const [insertModalData, setInsertModalData] = useState({})
    const notificationApi = useNotificationInstance()

    const [modalData, setModalData] = useState({});
    const [queryInput, setQueryInput] = useState(showPageData.queryString);
    const [uploadSendData, setUploadSendData] = useState<any>();
    /** 页面数据刷新状态 */
    let loading = false;
    let initialState = {
        apiId
    } as any;

    if (total === 0) { rows = undefined };
    useEffect(() => {

        const initStartData = async () => {
            if (typeof apiId !== "undefined") {
                initialState[apiId] = {
                    data: {
                        current: 1,
                        pageSize: 10,
                        queryString: undefined,
                        orderByString: ''
                    },
                    fetchUrl: wmService,
                    sendPageData,
                };

                InitData(initialState);
            }
        }
        initStartData();


        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    /**
     * 改变单一项数据
     * @param id 主键id
     * @param columnName 字段名称，例如：isUse，isCheck...
     * @param value 所要改变的内容
     * @param apiId 接口名称
     * @returns 
     */
    const handleChangeOne = async (id: any, columnName: string, value: boolean, apiId: string) => {
        const bodys = {
            apiId: apiId,
            apiVariable: id,
            apiExtend: 'updateOne',
            apiData: {
                columnName,
                value,
                mountDataOptions:{
                    userMount: {
                        isUserLogin: true
                      }
                }
            }
        };
        fetchApi.PUT(wmService, bodys).then((res) => {
            if (res.success === true) {
                showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message:'updateOne编辑成功',
                                description:  'apiId：' + bodys.apiId + '，columnName：' +
                    bodys.apiData.columnName + '，value：' + bodys.apiData.value
                                });
                InitData({
                    ...showPageData,
                });
                return true;
            }
        }).catch((err) => {
             showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message:"编辑失败",
                                description: err.message
                                });
            return false;
        })


    };

    const handleChangeDefault = async (record: any, apiId: string) => {
        const bodys = {
            apiId: apiId,
            apiExtend: 'changeDefault',
            apiData: {
                id: record.id,
                [extendIdName!]: record[extendIdName!],
                extendIdName,
            }
        };

        fetchApi.PUT(wmService, bodys).then((res) => {
            if (res.success === true) {
                 showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message:'changeDefault编辑成功',
                                description:    'apiId：' + bodys.apiId + '，columnName：' +
                    bodys.apiData.columnName + '，value：' + bodys.apiData.value });
                InitData({
                    ...showPageData,
                });
                return true;
            }
        }).catch((err) => {
             showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message:"编辑失败",
                                description: err.message
                                });
            return false;
        })


    };
    //ref 可使用WebUniversal(父)组件函数
    useImperativeHandle(ref, () => (
        {
            /**
             * 更改一项数据
             * @param id 
             * @param columnName 
             * @param value 
             * @param urlName 
             * @returns 
             */
            handleChangeOne: (id: any, columnName: string, value: boolean, urlName: string) =>
                handleChangeOne(id, columnName, value, urlName),
            /**
             * 刷新数据
             * @param currentData 
             * @returns 
             */
            initData: (currentData: initDataType) => {
                if (typeof currentData !== 'undefined') {
                    const currentApiId = currentData.apiId;
                    const currnetSendPageData = currentData.sendPageData;

                    if (typeof currentApiId !== 'undefined') {
                        showPageData.apiId = currentApiId;
                        if (typeof currnetSendPageData !== 'undefined') {
                            showPageData[currentApiId].sendPageData = currnetSendPageData
                        }
                    }
                }
                InitData({
                    ...showPageData,
                })


            },

            /** 后端数据wmService */
            wmService,
            defaultService: wmService,
            handleNew: () => openModal('NEW', ''),
            openModal,
            handleEdit,
            queryData

        }));

    /**
     * 删除动作
     * @param record 记录集合
     * @param isChecked checkBox checked
     * @returns 
     */
    const handleDelete = async (record: any, isChecked: boolean) => {

        if (typeof coverHandleDelete !== 'undefined') {
            coverHandleDelete(record, isChecked);
            return
        }
        let id = record.id;
        if (typeof primaryKeyName !== "undefined") {
            id = record[primaryKeyName]
        }
        if (isChecked === true) {
            if (typeof beforeHandleDeleteCheckBox !== 'undefined') {
                await beforeHandleDeleteCheckBox(record);
            }
        }
        fetchApi.DELETE(wmService, {
            apiId,
            apiVariable: id,
            apiExtend: "remove",
            apiData:{
                mountDataOptions:{
                    userMount: {
                        isUserLogin:true
                    }
                   },
            }
        }).then(async res => {
            if (res.success === true) {
                if (isChecked === true) {
                    if (typeof afterHandleDeleteCheckBox !== 'undefined') {
                        await afterHandleDeleteCheckBox(record);
                    }
                }
                 showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message:"删除成功",
                                    description:  "success"
                                });;
                //刷新表格内容
                InitData({
                    ...showPageData,
                });
            }
            else {
                 showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message:"删除失败",
                                    description:  "error"
                                });;
            }
        }).catch(err => {

             showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message:'删除失败',
                                description:     err.message + '，[' + err.errors.status + ']' });
        })
    };

    //编辑
    const handleEdit = (recordData: any) => {
        let id = recordData.id;
        if (typeof primaryKeyName !== "undefined") {
            id = recordData[primaryKeyName]
        }
        openModal('EDIT', id);
    };

    //文件上传
    const hanldUpload = async (e: any) => {
        setUploadSendData({
            id: e,
            disabledUpload,
            // handleChangeOne,

        })
        setModalUploadopen(true);
    };

    //关闭上传
    const onCloseUpload = (state: boolean) => {
        setModalUploadopen(false);
        // if (state === true) {
        //刷新表格内容
        InitData({
            ...showPageData,
        });
        // }
    };

    //排序
    const handleSort = (sort: any) => {
        let orderByString = '';
        switch (sort.order) {
            case 'descend':
                orderByString = sort.field + '@DESC'
                break;
            case 'ascend':
                orderByString = sort.field + '@ASC'
                break;
            default:
                break;
        }
        //刷新表格内容
        if (loading === false) {
            initialState[apiId] = {
                data: {
                    orderByString,
                },
            };
            InitData(initialState);
            loading = true;
        }
    };

    let behaviorsData: behaviorsDataType = {
        showImage,
        showUploadFile,
        showStates,
        showOperation,
        showDeposit,
        showAutomatic,
        showDelete,
        showEdit,
        showDefault,
        hanldUpload,
        handleDelete,
        handleEdit,
        handleChangeOne,
        handleChangeDefault,
        showSend,
        apiId,
        primaryKeyName,
        behaviorsColumns: props.props.columns,
        arrayFilesName,
        deleteButtonConfig,
        showReserve
    };

    let columns = WebBehaviors(behaviorsData);

    let sendData = {
        type: '',
        id: '',
        sendPageData: {},
        extendedParameter
    }

    //打开[新增-编辑-复制]框
    const openModal = (type: string, id: string, ep?: any) => {
        sendData.type = type;
        sendData.id = id;
        sendData.sendPageData = sendPageData;
        sendData.extendedParameter = ep ? ep : extendedParameter;
        setModalData(sendData);
        setModalopen(true);
    }

    //子组件回调函数，关闭modal
    const onCloseModal = (state: boolean) => {
        setModalopen(false)
        if (state === true) {
            //刷新表格内容
            InitData({
                ...showPageData,
            });
        }
    }




    /** 查询 */
    const handleQuery = (e: any) => {

        let value = e.target.value;


        setQueryInput(value);
    };

    const queryData = (queryString: any) => {  
          
        initialState[apiId] = {
            data: {
                queryString: typeof queryString === 'undefined' ? '' : queryString,
                current: data.queryString === queryString ? data.current : 1,
            },
        };
        InitData(initialState);
    };

    // 表格分页属性
    const paginationProps = {
        showSizeChanger: true,
        showQuickJumper: false,
        showTotal: () => `共${total}条`,
        pageSize,
        current,
        total,
        onShowSizeChange: (current: number, pageSize: number) => changePageSize(pageSize, current),
        onChange: (current: number) => changePage(current, pageSize),
    };

    // 回调函数，切换页数
    const changePage = (current: number, pageSize: number) => {
        if (loading === false) {
            initialState[apiId] = {
                data: {
                    current,
                    pageSize,
                },
            };
            InitData(initialState);
            loading = true;
        }
    }

    // 回调函数,每页显示多少条
    const changePageSize = (pageSize: number, current: number) => {

        if (loading === false) {
            initialState[apiId] = {
                data: {
                    current,
                    pageSize,
                },
            };
            InitData(initialState);
            loading = true;
        }
    };



    //根据日期回显数据
    const handleDate = (e: any) => {

        initialState[apiId] = {
            data: {
                pageSize,
                current,
            },
            sendPageData: {
                bookingDate: moment(e).format("YYYY-MM-DD")
            }
        };

        InitData(initialState);

    }



    return (
        <>
            <Space style={{ marginBottom: 16 }}>
                {showAdd === true || typeof showAdd === 'undefined' ?
                    <Button disabled={disabledAdd} onClick={() => openModal('NEW', '')}>新增{titleName}</Button>
                    : ''}
                {showQuery === true || typeof showQuery === 'undefined' ?
                    <>
                        <Tooltip placement="topLeft" title={queryTitle}>
                            <Input
                                placeholder="请输入查询内容"
                                defaultValue={queryInput}
                                onChange={(e: any) => handleQuery(e)}

                               onKeyDown={(e: any) => {
                                    if (e.key === 'Enter')
                                        queryData(queryInput)
                                }} />
                        </Tooltip>
                        <Button icon={<SearchOutlined />} onClick={() => queryData(queryInput)}>查询</Button>

                    </>
                    : ''}
                {showPrint ?
                    <>

                        {/* <Button onClick={() => DownloadExcel(props, rows)} >下载EXCEL文件</Button> */}
                    </>
                    : ''}
                {showDate ?
                    <>
                        <DatePicker
                            // defaultValue={moment(data.bookingDate)}
                            format="YYYY-MM-DD"
                            onChange={handleDate}
                        />
                    </>
                    : ''}


            </Space>
            <Table
                id='table'
                rowKey={row => (primaryKeyName ? row[primaryKeyName] : row.id)}
                columns={columns}
                dataSource={rows}
                indentSize={indentSize}
                childrenColumnName={childrenColumnName}
                pagination={pagination === true ? paginationProps : false}
                expandable={{
                    expandIcon: ({ expanded, onExpand, record }) =>
                        record[childrenColumnName!] && record[childrenColumnName!].length > 0 ? (
                            <span style={{marginRight:"5px"}} onClick={(e) => onExpand(record, e)}>
                                {expanded ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
                            </span>
                        ) : null,
                }}
                size="small"
                onChange={(_a, _b, par) => handleSort(par)}
                //总结栏
                summary={() => {
                    if (typeof summaryRender === 'undefined') {
                        return null;
                    }
                    let sumData
                    if (data['extendData']) {
                        sumData = data['extendData'][summaryRender.sumDataName]
                        return (
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>总计:</Table.Summary.Cell>
                                <Table.Summary.Cell index={0}>
                                    {sumData}
                                </Table.Summary.Cell >
                            </Table.Summary.Row>
                        )
                    }
                }}
            //
            />
            {modalopen ? (<EditComponent closeModal={onCloseModal} data={modalData} />) : null}
            {modalUploadopen ? (<UploadFileComponent onClose={onCloseUpload} data={uploadSendData} />) : null}

        </>
    )
}

/**
 * 通用Web组件 WebUniversal 
 * @param props 
 * @param ref 
 * @returns 
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true })
    (forwardRef(WebUniversal));
