import React, { useContext } from 'react';
import { SocketContext } from '../../App';
import { ArgsProps } from 'antd/es/notification';
import { NotificationInstance } from 'antd/es/notification/interface';

export const useNotificationInstance = () => {
  const useContextData = useContext(SocketContext);
  const { api } = useContextData
  return api;
};

export const showCustomNotification = (notificationApi: NotificationInstance, options: ArgsProps) => {

  let { type, message, description, placement, duration } = options;
  type = type ? type : "success";
  duration = duration ? duration : 2;
  placement = placement ? placement : "topRight";

  // 使用notificationApi来显示通知
  notificationApi[type]({
    type,
    message,
    description,
    placement,
    duration,
    // 你可以在这里添加更多的通知配置
  });
};