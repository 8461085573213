import { useEffect } from 'react';
import AntDraggableModal from '../../../../utils/extend/AntDraggableModal'
import { Form, Input, InputNumber, Switch } from 'antd';
import fetchApi from '../../../../utils/api/fetchApi';
import systematic from '../../../../config/systematic';
import antMessage from '../../../../utils/extend/AntdNotification'
import { showCustomNotification, useNotificationInstance } from '../../../../utils/extend/AntdNotificationEx';

export default function SetInventory(props: any) {
    const { data, closeModal } = props;
    const { apiId, exType, exId } = data;
    const notificationApi = useNotificationInstance()

    const [form] = Form.useForm(); //form 
    /** 后端数据wmService */
    let wmService: any = systematic.wmService;
    const handleCancel = (e: any) => {
        closeModal(false)

    }

    const handleOk = (e: any) => {
        form.validateFields()
            .then(async (values) => {

                switch (data.type) {
                    case 'NEW':
                        fetchApi.POST(wmService, {
                            apiId,
                            apiData: {
                                ...values,
                                [exType]: exId

                            }
                        }).then(res => {
                             showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message: '编辑成功',
                                    description: 'success',
                                });;
                            closeModal(true);
                        }).catch(err => {
                             showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message:"错误，无法保存",
                                    description:  err.message + ' | status：' + err.errors.status,
                                });
                        })
                        break;

                    case 'EDIT':
                        fetchApi.PUT(wmService, {
                            apiId,
                            apiVariable: data.id,
                            apiData: values
                        }).then(res => {

                             showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message: '编辑成功',
                                    description: 'success',
                                });;
                            closeModal(true);
                        }).catch(err => {

                            console.log("err", err);
                             showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message:"错误，无法保存",
                                    description:  err.message + ' | status：' + err.errors.status,
                                });
                        })
                        break;
                }

            })
            .catch((err: any) => {
                 showCustomNotification(notificationApi!, {
                                    type: 'warning',
                                    message:"无法通过数据校验",
                                description:  "请输入所需数据!"
                                });;
            })
        closeModal(true)

    }
    useEffect(() => {
        initData()
        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据


    const initData = () => {
        switch (data.type) {
            case "EDIT":
                fetchApi.GET(wmService, {
                    apiId: data.apiId,
                    apiExtend: "showSingle",
                    apiVariable: data.id,
                    // apiData: {
                    //     mountDataOptions: {
                    //         userMount: {
                    //             isDept: true,
                    //         }
                    //     },
                    // }

                }).then((res) => {
                    if (res.success) {
                        form.setFieldsValue({
                            ...res.data
                        })
                    }
                }).catch((err) => {

                })
                break;
            case "NEW":


                break;

        }



    }
    return (
        <AntDraggableModal
            title={data.type === "NEW" ? "新增" : "修改"}
            open={true}
            maskClosable
            okText='确定'
            cancelText='取消'
            onCancel={handleCancel}
            onOk={handleOk}
            closable={false}
            width={"300px"}
        // confirmLoading={loading}

        >
            <Form
                form={form}
                name="Edit"
                initialValues={{ "sortBy": 999 }}
                onFinish={handleOk}
               onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        handleOk(e)
                    }
                }}
            >
                <Form.Item
                    label="id"
                    name="id">
                    <Input disabled={true} />
                </Form.Item>

                <Form.Item
                    label="库存"
                    name="quantity"
                    rules={[{ required: true, message: '库存!' }]}>
                    <InputNumber />
                </Form.Item>



                <Form.Item
                    label="状态"
                    name="isUse"
                    valuePropName="checked"
                    initialValue={true}>
                    <Switch checked={true}
                        checkedChildren="启用"
                        unCheckedChildren="关闭" />
                </Form.Item>

            </Form>



        </AntDraggableModal>
    )
}
