import { Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import systemConfig from '../../config/systematic';
import fetchApi, { httpGet } from '../../utils/api/fetchApi';
import AntDraggableModal from '../../utils/extend/AntDraggableModal'
import getList from '../universal/getList';
import antMessage from '../../utils/extend/AntdNotification'
import { showCustomNotification, useNotificationInstance } from '../../utils/extend/AntdNotificationEx';
export default function MoonExchange(props: any) {
    const [form] = Form.useForm(); //form 
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const { closeModal,data } = props
    const notificationApi = useNotificationInstance()
    
    /**分店数据 */
    const [branchStoreData, setbranchStoreData] = useState<any>()
    /**票据状态数据 */
    // const [moonStatusData, setMoonStatusData] = useState<any>()
    /**票据类型数据 */
    // const [moonTypeData, setMoonTypeData] = useState<any>()
    useEffect(() => {
       form.setFieldsValue({exchangebranchStore:data.salebranchStore})

        const fetchData = async () => {
            // httpGet(wmService, {
            //     apiId: "generalData",
            //     apiExtend: 'showList',
            //     apiVariable: "moonStatus",
            // }).then(res => {
            //     if (res.success) {
            //         setMoonStatusData(res.data)
            //     }
            // })
            // httpGet(wmService, {
            //     apiId: "moonType",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     setMoonTypeData(res.data)
            // })

            httpGet(wmService, {
                apiId: "branchStore",
                apiExtend: 'showList'
            }).then(res => {
                setbranchStoreData(res.data)
            })


        }
        fetchData()
    }, [])

    const handleOk = (e: any) => {
        form.validateFields()
        .then(async (values: any) => {
            
            fetchApi.PUT(wmService,
                {
                    apiId: 'moon',
                    apiExtend: 'exchange',
                    apiVariable: data.id,
                    apiData: {
                        moonStatus: 2,
                        exchangebranchStore:values.exchangebranchStore,
                        mountDataOptions:{
                            userMount: {
                                isDept:true,
                            }
                           },
                    }
                }).then(res => {
    
                    antMessage('success', '兑换成功', "success")
                    closeModal(true)
                }).catch(err => {
                    closeModal(false)
                    antMessage('error', '删编辑失败', 'error');
                })
        })
        .catch((err: any) => {
             showCustomNotification(notificationApi!, {
                                    type: 'warning',
                                    message:"无法通过数据校验",
                                description:  "请输入所需数据!"
                                });;
        });
       
        
    };
    const handleCancel = (e: any) => {
        closeModal(false)
    };
    return (
        <>
            <AntDraggableModal
                title={`兑换`}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={400}
            >

                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Form.Item
                        label="兑换分店"
                        name="exchangeBranchStore"
                        rules={[{ required: true, message: '请输入兑换分店!' }]}>
                        <Select
                            showSearch
                            placeholder="兑换分店"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(branchStoreData, 'branchStoreName')}
                        </Select>
                    </Form.Item>

                    {/* <Form.Item
                        label="票据类型"
                        name="moonTypeId"
                        rules={[{ required: true, message: '请输入票据类型!' }]}>
                        <Select
                            showSearch
                            placeholder="票据类型"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(moonTypeData, 'moonType')}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="票据状态"
                        name="moonStatus"
                        rules={[{ required: true, message: '请输入票据状态!' }]}>
                        <Select

                            showSearch
                            placeholder="票据状态"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(moonStatusData, 'name')}
                        </Select>
                    </Form.Item> */}


                </Form>
            </AntDraggableModal>
        </>
    )
}
