import React, { useContext, useEffect, useRef, useState } from 'react'
import WebUniversal, { initDataType, WUExtendPropsType } from '../../../system/WebUniversalNewEx'
import { columnsheadId } from '../../../universal/columnshead';
import { Button, Switch, Tooltip } from 'antd';
import dayjs from 'dayjs';
import BeOnDutyEdit from './BeOnDutyEdit';
import fetchApi from '../../../../utils/api/fetchApi';
import systemConfig from '../../../../config/systematic';
import antMessage from '../../../../utils/extend/AntdNotification'
import { SocketContext } from '../../../../App';
import { showCustomNotification, useNotificationInstance } from '../../../../utils/extend/AntdNotificationEx';

export default function BeOnDuty(props: any) {
    /** api服务id */
    const apiId = 'beOnDuty';
    const { isUser } = props;
    let wmService: any = systemConfig.wmService;
    const ref = useRef<any>(undefined);
    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;
    /**选中的所有数据 */
    const [selectData, setSelectData] = useState<any>();
    const notificationApi = useNotificationInstance()

    //表头
    const columnsHead = [
        {
            title: '值班人',
            dataIndex: 'user',
            render: (text: any) => (text ? text.userName : "")
        },
        {
            title: '登录时间',
            dataIndex: 'loginTime',
            render: (text: any) => (text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "")
        },
        {
            title: '登出时间',
            dataIndex: 'logoutTime',
            render: (text: any) => (text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "")
        },
        {
            title: '预支钱款',
            dataIndex: 'advancePayment',
        },

        {
            title: '实交价格',
            dataIndex: 'totalRealMoney',
        },
        {
            title: '在值',
            dataIndex: 'isBeOnDuty',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '打印',
            align: "center",
            render: (text: any, record: any) => (<Button onClick={() => reprintOnDutyReport(record.id)} disabled={record.isBeOnDuty} style={record.printNumber >= 1 ? { "backgroundColor": "yellow", "color": "black" } : {}}>打印</Button>)
        },
        {
            title: '打印结算',
            align: "center",
            render: (text: any, record: any) => (<Button onClick={() => reprintOnDutyReport(record.id,"settlement")}>打印结算</Button>)
        },

    ];
   
    useEffect(() => {

        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                const currentData: initDataType = {
                    apiId,
                    sendPageData: { isUser,branchStoreId:contextData?.currentBranchStoreId }
                }
                ref.current.initData(currentData);
                setContextData({ ...contextData, refresh: false });
            }
        }

    },[contextData?.currentBranchStoreId])
    /** 子表头内容 */
    const expandableColumnsHead: Array<any> = [
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 10 ? text.substring(0, 10) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '支付名称',
            dataIndex: 'paymentMethodName',
        },
        {
            title: '支付金额',
            dataIndex: 'payAmount',
        },
        {
            title: '原路退',
            dataIndex: 'refundAmount',
        },
        {
            title: '次数',
            dataIndex: 'refundCounts',
        },
        {
            title: '现金退',
            dataIndex: 'cashRefundAmount',
        },
        {
            title: '次数',
            dataIndex: 'cashRefundCounts',
        },
    ]

    const columns = columnsheadId
        .concat(columnsHead as []);
    /** 子表头 */
    const expandableColumns = expandableColumnsHead;
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '值班',
        apiId,
        columns,
        showAdd: false,
        showEdit: isUser ? false : true,
        showStates: false,
        pagination: true,
        showDelete: true,
        sendPageData: { isUser, branchStoreId:contextData?.currentBranchStoreId  },
        expandableRowSelection: {
            type: "checkbox",
            onChange: (fatherId: any, selectedRowKeys: React.Key[]) => {
                let obj = {
                    [fatherId]: selectedRowKeys
                }
                setSelectData({ ...selectData, ...obj })
            },
        },
        mountDataOptions: {
            "userMount": {
                "isDept": true
            }
        },
        EditComponent: BeOnDutyEdit,

        //子表格
        isExpandable: true,
        expandableDataName: 'bdPays',
        expandableColumns,
    };
    const reprintOnDutyReport = (id: string, printType?: any) => {
        if (!id) return false;
        let data;
        if (printType && printType === "settlement") {
            if(!selectData){
                 showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message:"失败",
                                    description:  "选中数据不存在"
                                });
                return false
            }
            data = selectData[id];
            if(!data||data.length===0){
                 showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message:"失败",
                                    description:  "选中数据不存在"
                                });
                return false
            }
        }
        fetchApi.POST(wmService, {
            apiId: "beOnDuty",
            apiExtend: "reprintOnDutyReport",
            apiData: {
                id,
                printType,
                ids:data
            }
        }).then((res) => {
            if (res.success) {
                const currentData: initDataType = {
                    apiId,
                    sendPageData: {isUser,branchStoreId:contextData?.currentBranchStoreId }
                }
                ref.current.initData(currentData);
                 showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message:"成功",
                              
                                });
            }

        }).catch((err) => {
            antMessage("error", "失败", err)
        })
    }
    return (
        <WebUniversal ref={ref} props={extendProps} />
    )
}

