import { useContext, useEffect, useRef, useState } from 'react';
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { Button, Col, Form, Modal, Popconfirm, Row, Select, Space, Switch, Tooltip } from 'antd';
import BranchStoreMenuWarehouseEdit from '../menu/BranchStoreMenuWarehouseEdit';
import Synchronize from './Synchronize';
import WarehouseToMenu from '../menu/WarehouseToMenu';
import BranchStoreMenuWarehouseSpec from '../menu/BranchStoreMenuWarehouseSpec';
import CopyMenuWarehouse from './CopyMenuWarehouse';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
// import BranchStoreList from '../../generalComponents/BranchStoreList';
import getList from '../../universal/getList';
// import fetchApi from '../../../utils/api/fetchApi';
// import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification';
import { SocketContext } from '../../../App';
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';

//组件 BranchStoreMenuWarehouse 
export default function BranchStoreMenuWarehouse(props: any) {

    const ref = useRef<any>(undefined);
    const [form] = Form.useForm();
    const notificationApi = useNotificationInstance()

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    //同步窗口
    const [synchronizeVisible, setSynchronizeVisible] = useState(false);

    //同步数据
    const [synchronizeData, setSynchronizeData] = useState<any>();

    //不打印窗口
    const [noPrintOrderVisible, setNoPrintOrderVisible] = useState(false);

    //同步数据
    const [noPrintOrderData, setNoPrintOrderData] = useState<any>();

    //转移菜式
    const [warehouseToMenuVisible, setWarehouseToMenuVisible] = useState(false);

    //选择转移菜式
    const [branchStoreMenuWarehouseData, setBranchStoreMenuWarehouseData] = useState<any>();

    //规格数据
    const [branchStoreMenuWarehouseSpecData, setBranchStoreMenuWarehouseSpecData] = useState<any>();

    //规格窗口
    const [branchStoreMenuWarehouseSpecVisible, setBranchStoreMenuWarehouseSpecVisible] = useState(false);

    //复制菜式窗口
    const [copyMenuWarehouseVisible, setCopyMenuWarehouseVisible] = useState(false);

    //复制菜式数据
    const [copyMenuWarehouseData, setCopyMenuWarehouseData] = useState<any>();

    /** 菜品类型数据 */
    const [foodTypeData, setFoodTypeData] = useState<any>();

    const [foodTypeIdData, setFoodTypeIdData] = useState<any>();

    /** 分类数据 */
    const [shopTypeData, setShopTypeData] = useState<any>();
    const [printOrderTypeData, setPrintOrderTypeData] = useState<any>([])

    /** 部门数据 */
    const [deptData, setDeptData] = useState<any>();

    const [deptIdData, setDeptIdData] = useState<any>();

    /** api服务id */
    const apiId = 'branchStoreMenuWarehouse';

    // /** 后端数据wmService */
    // let wmService: any = systemConfig.wmService;

    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // wmService = ref.current.wmService;
        }

        //同步获取数据 async - await
        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'printOrderType',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setPrintOrderTypeData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'foodType',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "999", name: "所有分类" }, ...res.data];
                    setFoodTypeData(newData);
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'dept',
                apiExtend: 'showBranchDeptList',
                apiData: {
                    // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                    branchStoreId: contextData?.currentBranchStoreId,
                    mountDataOptions: {
                        userMount: {
                            isDept: true
                        }
                    }
                }
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "999", deptName: "所有部门" }, ...res.data];
                    setDeptData(newData);
                }
            });
        }

        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
            }
        }

        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextData?.currentBranchStoreId]); //初始化数据

    //打开添加规格
    const handleBranchStoreMenuWarehouseSpec = (record: any) => {
        //console.log(record)

        sendData.id = record.id;
        sendData.foodName = record.foodName;
        sendData.branchStoreMenuWarehouseId = record.id;
        sendData.menuWarehouseId = record.menuWarehouseId;
        sendData.foodType = record.foodType;
        setBranchStoreMenuWarehouseSpecData(sendData);
        setBranchStoreMenuWarehouseSpecVisible(true);
    };

    // 打开同步/关联
    const handleSynchronize = (record: any, key: any) => {
        // console.log(record);

        sendData.branchStoreMenuWarehouseId = record.id;
        sendData.foodName = record.foodName;
        sendData.foodNameEn = record.foodNameEn;
        sendData.description = record.description;
        sendData.introduction = record.introduction;
        sendData.branchStoreMenuWarehouseSpecRow = record.branchStoreMenuWarehouseSpecs;
        sendData.branchStoreId = record.branchStoreId;
        sendData.brandId = record.branchStore.brandId;
        sendData.menuWarehouseId = record.menuWarehouseId;
        sendData.key = key;
        sendData.path = 'BranchStoreMenuWarehouse';
        setSynchronizeData(sendData);
        setSynchronizeVisible(true);
    };

    const handleOk = (e: any) => {
        form.validateFields() //数据校验
            .then(async (values: any) => {
                // console.log(values);
                fetchApi.POST(wmService, {
                    apiId: 'branchStoreMenuWarehouse',
                    apiExtend: 'noPrintOrder',
                    apiData: {
                        ...values,
                        mountDataOptions: {
                            userMount: {
                                isDept: true
                            }
                        }
                    }
                }).then(res => {
                    if (res.success) {
                        antMessage('success', '添加成功', 'success');
                        onCloseNoPrintOrder();
                    }
                }).catch(err => {
                    antMessage('error', '错误，无法保存', err.message + ' | status:' + err.errors.status);
                })
            })
            .catch((err: any) => {
                 showCustomNotification(notificationApi!, {
                                    type: 'warning',
                                    message:"无法通过数据校验",
                                description:  "请输入所需数据!"
                                });;
            });
    };

    // 打开分类设置不打印窗口
    const handleNoPrintOrder = () => {
        setNoPrintOrderVisible(true);

        fetchApi.GET(wmService, {
            apiId: "shopType",
            apiExtend: 'showList',
            apiData: {
                // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                branchStoreId: contextData?.currentBranchStoreId,
                mountDataOptions: {
                    userMount: {
                        isDept: true
                    }
                }
            }
        }).then(res => {
            if (res.success) {
                setShopTypeData(res.data);
            }
        });
    };

    //关闭分类设置不打印窗口
    const onCloseNoPrintOrder = () => {
        form.setFieldsValue({ shopTypesId: [] });
        form.setFieldsValue({ noPrintOrder: [] });
        form.setFieldsValue({ wxNoPrintOrder: [] });
        form.setFieldsValue({ isDelete: false });
        setNoPrintOrderVisible(false);
    }

    //关闭同步页面
    const onCloseSynchronize = async (status: boolean) => {
        setSynchronizeVisible(false);
        if (status) {
            await initData();
        }
    }

    //关闭门店菜式规格页面
    const onCloseBranchStoreMenuWarehouseSpec = async (status: boolean) => {
        setBranchStoreMenuWarehouseSpecVisible(false);
        if (status) {
            await initData();
        }
    }

    //关闭穿梭框页面
    const onCloseWarehouseToMenu = async (status: boolean) => {
        setWarehouseToMenuVisible(false);
        if (status) {
            await initData();
        }
    }

    /**
     * 复制菜式
     */
    const handleCopy = () => {
        sendData.path = 'BranchStoreMenuWarehouse';
        setCopyMenuWarehouseData(sendData);
        setCopyMenuWarehouseVisible(true);
    };

    /**
     * 更新所有菜式的英文
     */
    // const updateAllEn = (record: any) => {
    //     // console.log(record);
    //     if (record.menuWarehouseId) {
    //         fetchApi.POST(wmService, {
    //             apiId: "branchStoreMenuWarehouse",
    //             apiExtend: 'updateMenuWarehouseEn',
    //             apiData: {
    //                 branchStoreMenuWarehouseId: record.id
    //             }
    //         }).then(res => {
    //             if (res.success) {
    //                 // console.log(res.data);
    //                 console.log("门店菜式：【 " + record.foodName + "】  英文更新为【 " + res.data.foodNameEn + " 】");
    //                 if (res.data.menus.length > 0) {
    //                     for (var menus = 0; menus < res.data.menus.length; menus++) {
    //                         console.log("---------【" + res.data.menus[menus].menuHead.menuHeadName + "】菜式：【 " + res.data.menus[menus].foodName + "】  英文更新为【 " + res.data.foodNameEn + " 】");
    //                     }
    //                 }
    //                 console.log("")
    //                 console.log("")
    //             }
    //         });
    //     }
    // };

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                // updateAllEn(record);
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '菜式',
            dataIndex: 'foodName',
            render: (text: any, record: any) => {
                if (sessionStorage.getItem('isAdmin') === 'true') {
                    return (
                        <Tooltip placement="topLeft" title={record.menuWarehouse ? "【" + record.menuWarehouse.brandId + "】" + record.menuWarehouse.foodName : '暂时未关联仓库菜式'} color='green'>
                            <div>{text}</div>
                        </Tooltip>
                    )
                }
                else {
                    return (
                        <div>{text}</div>
                    )
                }
            }
        },
        {
            title: '类别',
            dataIndex: 'shopTypeName',
            render: (text: any, record: any) => {
                if (record.shopType) {
                    return (
                        <div>{record.shopType.shopTypeName}</div>
                    )
                }
            }
        },
        {
            title: '简码',
            dataIndex: 'selfId',
            sorter: true,
        },
        {
            title: '类型',
            dataIndex: 'foodTypeName',
        },
        {
            title: '出单部门',
            dataIndex: 'deptName',
            render: (text: any, record: any) => {
                if (record.dept) {
                    return (
                        <Tooltip placement="topLeft" title={record.isPrint ? '设置为【打印】 ' : '设置为【不打印】 '} color='red'>
                            <div>{record.dept.deptName + (record.specialDept ? " ----- " + record.specialDept.deptName + " (特殊)" : "")}</div>
                        </Tooltip>
                    )
                }
                else {
                    return (
                        <p>
                            <div style={{ color: 'red', fontSize: '15px' }}>设置出单部门后才能进行后续操作</div>
                        </p>
                    )
                }
            }
        },
        {
            title: '单位',
            dataIndex: 'productUnitName',
            render: (text: any, record: any) => {
                if (record.productUnit) {
                    return (
                        <div>{record.productUnit.productUnitName}</div>
                    )
                }
            }
        },
        {
            title: '价钱',
            dataIndex: 'price',
            render: (text: any, record: any) => {
                if (record.branchStoreMenuWarehouseSpecs.length > 0) {
                    return (
                        <div style={{ fontSize: 20, color: 'yellow' }}>
                            {record.branchStoreMenuWarehouseSpecs.length === 1 ? record.branchStoreMenuWarehouseSpecs[0].price : record.branchStoreMenuWarehouseSpecs[0].price + " - " + record.branchStoreMenuWarehouseSpecs[record.branchStoreMenuWarehouseSpecs.length - 1].price}
                        </div>
                    )
                }
            }
        },
        {
            title: '规格',
            dataIndex: '',
            key: 'branchStoreMenuWarehouseSpec',
            render: (text: any, record: any) => {
                if (record.dept) {
                    if (record.branchStoreMenuWarehouseSpecs.length > 0) {
                        return (
                            <Space size="small">
                                <Button size={"small"} type="primary" onClick={() => handleBranchStoreMenuWarehouseSpec(record)}>规格</Button>
                            </Space>
                        )
                    }
                    else {
                        return (
                            <Space size="small">
                                <Button size={"small"} type="primary" danger onClick={() => handleBranchStoreMenuWarehouseSpec(record)}>规格</Button>
                            </Space>
                        )
                    }
                }
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) => {
                // console.log(record);
                if (record.dept) {
                    if (record.menuWarehouseId) {
                        if (record.branchStoreMenuWarehouseSpecs.length > 0) {

                            if (sessionStorage.getItem('isAdmin') === 'true') {
                                return (
                                    <Space size="small">
                                        <Button size={"small"} type="primary" onClick={() => handleSynchronize(record, 'ToMenuWarehouse')}>同步到...</Button>
                                        <span>&nbsp;&nbsp;</span>
                                        <Button size={"small"} type="primary" onClick={() => cancelConnect(record)}>取消关联仓库</Button>
                                    </Space>
                                )
                            }
                            else {
                                return (
                                    <Space size="small">
                                        <Button size={"small"} type="primary" onClick={() => handleSynchronize(record, 'ToMenuWarehouse')}>同步到...</Button>
                                        <span>&nbsp;&nbsp;</span>
                                        {/* <Button size={"small"} type="primary" onClick={() => cancelConnect(record)}>取消关联仓库</Button> */}
                                    </Space>
                                )
                            }
                        }
                    }
                    else {
                        if (record.branchStoreMenuWarehouseSpecs.length > 0) {
                            if (sessionStorage.getItem('isAdmin') === 'true') {
                                return (
                                    <Space size="small">
                                        <Button size={"small"} type="primary" onClick={() => handleSynchronize(record, 'ToMenuWarehouse')}>同步到...</Button>
                                        <span>&nbsp;&nbsp;</span>
                                        <Button size={"small"} type="primary" onClick={() => handleSynchronize(record, 'FromMenuWarehouse')}>关联</Button>
                                    </Space>
                                )
                            }
                            else {
                                return (
                                    <Space size="small">
                                        <Button size={"small"} type="primary" onClick={() => handleSynchronize(record, 'ToMenuWarehouse')}>同步到...</Button>
                                    </Space>
                                )
                            }

                        }
                    }
                }
            }
        },
        // {
        //     title: '排序',
        //     dataIndex: 'sortBy',
        // },
    ];

    /** 子表头内容 */
    const expandableColumnsHead: Array<any> = [
        // {
        //     title: "id",
        //     dataIndex: "branchStoreMenuWarehouseSpecName",
        //     render: (text: any, record: any) => {
        //         console.log("record", record);
        //         return <>
        //             {record.id}
        //         </>
        //     }
        // },
        {
            title: "规格",
            dataIndex: "branchStoreMenuWarehouseSpecName",
            render: (text: any, record: any) => {
                // console.log("record", record);
                return <>
                    {record.branchStoreMenuWarehouseSpecName}
                </>
            }
        },
        {
            title: "基础价",
            dataIndex: "price",
            render: (text: any, record: any) => {
                // console.log("record", record);
                return <>
                    {record.price}
                </>
            }
        },
        // {
        //     title: "排序",
        //     dataIndex: "sortBy",
        //     render: (text: any, record: any) => {
        //         // console.log("record", record);
        //         return <>
        //             {record.sortBy}
        //         </>
        //     }
        // },
    ]

    /** 子表头 */
    const expandableColumns = expandableColumnsHead;
    const columns = columnsheadId
        .concat(columnsHead as []);

    //传关参数
    let sendData = {
        type: 'EDIT',
        id: '',
        branchStoreMenuWarehouseId: '',
        menuWarehouseId: '',
        branchStoreMenuWarehouseSpecRow: '',
        foodName: '',
        foodNameEn: '',
        description: '',
        introduction: '',
        branchStoreId: '',
        brandId: '',
        foodType: '',
        key: '',
        path: ''
    }

    // 打开从菜式仓选择到门店
    const handleSelectMenuWarehouse = () => {

        sendData.key = 'menuWarehouseToBranchStore';
        setBranchStoreMenuWarehouseData(sendData);
        setWarehouseToMenuVisible(true);
    };

    const handleDelete = (record: any) => {
        fetchApi.DELETE(wmService, {
            apiId,
            apiExtend: "remove",
            apiVariable: record.id,
            apiData: {
                // branchStoreId: localStorage.getItem("currentBranchStoreId")
                branchStoreId: contextData?.currentBranchStoreId
            }
        }).then(async res => {
            if (res.success) {
                antMessage('success', res.data.message, 'success');
                await initData();
                // if (res.data.state) {
                //     closeModal(true);
                // }
            }
        })
    };

    const initData = async () => {
        // const menuHeadId = data.menuHeadId;
        const currentData: initDataType = {
            apiId,
            // sendPageData: { menuHeadId: menuHeadId }
        }
        ref.current.initData(currentData);
    }

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '门店菜式仓库',
        apiId,
        columns,
        showStates: true,
        showEdit: true,
        pagination: true,
        isAutomaticRefresh: true,
        showDelete: true,
        showAdd: true,
        EditComponent: BranchStoreMenuWarehouseEdit,
        // sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") }
        sendPageData: { branchStoreId: contextData?.currentBranchStoreId },
        coverHandleDelete: handleDelete,
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        },

        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'branchStoreMenuWarehouseSpecs',
    };

    const setInventory = () => {
        fetchApi.POST(wmService, {
            apiId: "branchStoreMenuWarehouse",
            apiExtend: "setInventory",
            apiData: {
                mountDataOptions: {
                    userMount: {
                        isDept: true
                    }
                },
            }
        }).then(async (res) => {
            await initData()

        }).catch((err) => {

        })
    }

    //查看不同类型的菜式
    const foodTypeChange = (e: any) => {
        setFoodTypeIdData(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                deptId: deptIdData,
                foodTypeId: e,
                // branchStoreId: localStorage.getItem("currentBranchStoreId")
                branchStoreId: contextData?.currentBranchStoreId
            }
        }
        ref.current.initData(currentData);
    }

    //查看不同部门的菜式
    const deptChange = (e: any) => {
        setDeptIdData(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                deptId: e,
                foodTypeId: foodTypeIdData,
                // branchStoreId: localStorage.getItem("currentBranchStoreId")
                branchStoreId: contextData?.currentBranchStoreId
            }
        }
        ref.current.initData(currentData);
    }

    const onRefresh = (item: any) => {
        const { branchStoreId } = item;
        fetchApi.GET(wmService, {
            apiId: 'dept',
            apiExtend: 'showBranchDeptList',
            apiData: {
                // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                branchStoreId: contextData?.currentBranchStoreId,
                mountDataOptions: {
                    userMount: {
                        isDept: true
                    }
                }
            }
        }).then(res => {
            if (res.success) {
                let newData = [{ id: "999", deptName: "所有部门" }, ...res.data];
                setDeptData(newData);
            }
        });

        form.setFieldsValue({ deptId: '999' });

        const currentData: initDataType = {
            apiId,
            sendPageData: { deptId: '999', foodTypeId: foodTypeIdData, branchStoreId }
        }
        ref.current.initData(currentData);

        setContextData({ ...contextData, refresh: false })
    }

    /**
     * 取消关联仓库
     */
    const cancelConnect = (record: any) => {
        fetchApi.GET(wmService, {
            apiId: "branchStoreMenuWarehouse",
            apiExtend: 'cancelConnect',
            apiVariable: record.id,
        }).then(async res => {
            if (res.success) {
                await initData();
            }
        });
    };

    return (
        <>
            <div>
                {/* <div>
                    <BranchStoreList form={form} onRefresh={onRefresh} setDeptData={setDeptData}></BranchStoreList>
                </div> */}
                {/* <br></br> */}
                <div>
                    <Form form={form}>
                        <Row>
                            <Col span={5}>
                                <Form.Item
                                    name="foodType"
                                    label="菜品类型"
                                >
                                    <Select
                                        // style={{ width: 150 }}
                                        showSearch
                                        defaultValue="所有类型"
                                        onChange={foodTypeChange}
                                        placeholder="菜品类型"
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {getList.generalEx(foodTypeData, {
                                            includeNull: false
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={5}>
                                <Form.Item
                                    name="deptId"
                                    label="部门"
                                >
                                    <Select
                                        // style={{ width: 150 }}
                                        showSearch
                                        defaultValue="所有部门"
                                        onChange={deptChange}
                                        placeholder="部门"
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {getList.general(deptData, "deptName")}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={14}>
                                <Space>
                                    {sessionStorage.getItem('isAdmin') === 'true'
                                        ? (<Button type="primary" onClick={handleSelectMenuWarehouse}>选取仓库菜式</Button>)
                                        : null}

                                    {sessionStorage.getItem('isAdmin') === 'true'
                                        ? (<Button type="primary" onClick={handleCopy}>将门店所有菜式复制到其他门店</Button>)
                                        : null}
                                    <Popconfirm title="确定要一键恢复(设置)库存吗?" onConfirm={setInventory}>
                                        <Button type='primary' >一键恢复(设置)库存</Button>
                                    </Popconfirm>

                                    <Button type="primary" onClick={handleNoPrintOrder}>分类设置单据不打印</Button>

                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <Modal
                title="分类批量设置单据不打印"
                open={noPrintOrderVisible}
                okText='确定'
                onOk={handleOk}
                onCancel={onCloseNoPrintOrder}
                style={{ fontSize: '20px' }}
                width={1000}
            >
                <Form
                    form={form}
                    name="Edit"
                >
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="选择分类"
                                name="shopTypesId"
                                rules={[{ required: true, message: '请选择分类!' }]}>
                                {/* <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择分类"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}
                                    options={shopTypeData}
                                >
                                </Select> */}
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择分类"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(shopTypeData, 'shopTypeName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="不打印订单(叫卖)"
                                name="noPrintOrder"
                                tooltip="叫卖模式 指定该菜不打印在某些单里，目前暂时只支持堂口单，客看单，厨总单">
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="订单类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(printOrderTypeData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="不打印订单(微信)"
                                name="wxNoPrintOrder"
                                tooltip="微信下单模式 指定该菜不打印在某些单里，目前暂时只支持堂口单，客看单，厨总单">
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="订单类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(printOrderTypeData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="增加/删除不打印订单"
                                name="isDelete"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="删除"
                                    unCheckedChildren="增加" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <br></br>
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>

            {synchronizeVisible
                ? (<Synchronize
                    closeModal={onCloseSynchronize} data={synchronizeData} />)
                : null}

            {branchStoreMenuWarehouseSpecVisible
                ? (<BranchStoreMenuWarehouseSpec
                    closeModal={onCloseBranchStoreMenuWarehouseSpec} data={branchStoreMenuWarehouseSpecData} />)
                : null}

            {warehouseToMenuVisible
                ? (<WarehouseToMenu
                    closeModal={onCloseWarehouseToMenu} data={branchStoreMenuWarehouseData} />)
                : null}

            {copyMenuWarehouseVisible
                ? (<CopyMenuWarehouse
                    closeModal={setCopyMenuWarehouseVisible} data={copyMenuWarehouseData} branchStoreId={contextData?.currentBranchStoreId} />)
                : null}
        </>
    )
}