import React, { useEffect, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import { Checkbox, Col, Divider, Form, Input, InputNumber, Radio, Row, Select, Switch, Table } from 'antd';
import systemConfig from '../../../config/systematic';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification'
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';



/**订单预结页面 */
export default function CateringOrderPrint(props: any) {
    const { data, closeModal } = props;

    const [form] = Form.useForm(); //form 


    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;


    const [serviceChargeData, setServiceChargeData] = useState<Array<any>>([]);


    const [printModeTypeData, setPrintModeTypeData] = useState<any>();

    /**服务费率 */
    const [serviceRate, setServiceRate] = useState<number>(0);

    /**打印机 */
    const [printData, setPrintData] = useState<number>(0)
    //帐单类型
    const [accountTypeData, setAccountTypeData] = useState<Array<any>>([]);
    //支付码数据
    const [paymentListData, setPaymentListData] = useState<Array<any>>([]);
    const [smallCharge, setSmallCharge] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false)
    const isPayCode = Form.useWatch("isPayCode", form) as any;
    const printModeType = Form.useWatch("printModeType", form) as any;
    const notificationApi = useNotificationInstance()

    const handleOk = (e: any) => {

        setLoading(true)
        form.validateFields() //数据校验
            .then(async (values: any) => {
                fetchApi.POST(wmService, {
                    apiId: "cateringOrder",
                    apiExtend: "preClosing",
                    apiData: {
                        id: data.id,
                        isIo: data.isIo,
                        ...values,
                        derateServiceCharge: parseFloat((values.derateServiceCharge).toFixed(2)),
                        serviceCharge: parseFloat((values.serviceCharge).toFixed(2)),
                        shouldServiceCharge: parseFloat((values.shouldServiceCharge).toFixed(2)),
                        smallCharge,
                        serviceRate,
                        orderPaymentModeId: data.orderPaymentModeId,
                        isReverseClosing: data.isReverseClosing
                        // counts:13
                        // menuSpe:selectMenuSpeData
                    }
                }).then(res => {
                    setLoading(false)
                    showCustomNotification(notificationApi!, {
                        type: 'success',
                        message: "预结成功",
                        description: "success",
                        duration: 0.5 
                    });
                    // antMessage('success', '预结成功', 'success', { duration: 0.5 });
                    closeModal(false);
                }).catch(err => {
                    setLoading(false)
                    showCustomNotification(notificationApi!, {
                        type: 'error',
                        message: "错误，无法保存",
                        description: err.message + ' | status：' + err.errors.status,
                    });
                })
            })
            .catch((err: any) => {

                console.log("err", err);

                showCustomNotification(notificationApi!, {
                    type: 'warning',
                    message: "无法通过数据校验",
                    description: "请输入所需数据!"
                });;
            });


    }
    const handleCancel = (e: any) => {
        closeModal(false)
        // if (data.caOrderStatus !== 998) {
        //     console.log("没执行22222");
        //     fetchApi.POST(wmService, {
        //         apiId: "cateringOrder",
        //         apiExtend: 'modifiedOrderState',
        //         apiData: {
        //             id:data.id,
        //             caOrderStatus:2
        //         }
        //     }).then(res => {
        //         console.log("res", res);

        //     })
        // }
    }

    //初始化数据
    const initData = () => {

        fetchApi.GET(wmService, {
            apiId: "cateringOrder",
            apiExtend: "showSingleEx",
            apiVariable: data.id,
            apiData: {
                orderPaymentModeId: data.orderPaymentModeId
            }

        }).then((res) => {

            let counts = res.data.counts
            let totalMoney = res.data.totalMoney ? res.data.totalMoney : 0;
            let agioMoney = res.data.agioMoney ? res.data.agioMoney : 0;
            let giftPrice = res.data.giftPrice ? res.data.giftPrice : 0;
            let serviceRate = res.data.serviceCharge.serviceCharge;
            let countSerPrice = res.data.countSerPrice ? res.data.countSerPrice : 0;
            // let freePrice=res.data.freePrice?res.data.freePrice:0;


            //未算服务费金额
            let recePriceIng = totalMoney - agioMoney - giftPrice;

            //应收服务费
            let shouldServiceCharge = recePriceIng * Number(serviceRate);
            //实收服务费
            let serviceCharge = countSerPrice * Number(serviceRate);
            //减免服务费
            let derateServiceCharge = shouldServiceCharge - serviceCharge;



            //初始应收/实付价格(未抹零)
            let receAndRealPrice = recePriceIng + serviceCharge;
            let smallChangePrice;
            if (Number(receAndRealPrice) < 1) {
                smallChangePrice = receAndRealPrice
            } else {
                smallChangePrice = Math.floor(receAndRealPrice);
            }


            //初始化抹零的价格
            let smallCharge = receAndRealPrice - smallChangePrice;

            form.setFieldsValue({
                serviceChargeId: res.data.serviceCharge.id,
                totalMoney,
                agioMoney,
                giftPrice,
                countSerPrice,
                shouldMoney: smallChangePrice,
                derateServiceCharge,
                shouldServiceCharge,
                serviceCharge,
                roomId: res.data.roomId,
                counts,
                roomTypeId: res.data.roomTypeId
                // freePrice
            })

            setServiceRate(serviceRate)
            setSmallCharge(smallCharge)
            // initOrderPrint()
        })
    }
    useEffect(() => {
        form.setFieldValue("isPayCode", true);
        form.setFieldValue("printModeType", 2);

        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "serviceCharge",
                apiExtend: "showList",
                apiData: {
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                        }
                    },
                }

            }).then((res) => {
                let data = res.data;
                setServiceChargeData(data)
            })
            fetchApi.GET(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'printOrderType',
                    apiExtend: 'showList',
                    apiData: {
                        ids: ["cateringCheckoutDetailAccountsOrder", "cateringCheckoutDeptAccountsOrder", "cateringCheckoutClassifyAccountsOrder"]
                    }
                }).then(res => {

                    if (res.success) {
                        setAccountTypeData(res.data)
                    }
                });

            fetchApi.GET(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'printModeType',
                    apiExtend: 'showLists',

                }).then(res => {
                    const { printModeType
                    } = res.data;
                    setPrintModeTypeData(printModeType)


                    if (res.success) {
                        // setAccountTypeData(res.data)
                    }
                });

            fetchApi.GET(wmService,
                {
                    apiId: 'printer',
                    apiExtend: 'showList',
                    apiData: {
                        mountDataOptions: {
                            userMount: {
                                isDept: true,
                            }
                        },
                    }

                }).then(res => {

                    if (res.success) {
                        setPrintData(res.data)

                    }
                });

            fetchApi.GET(wmService,
                {
                    apiId: 'paymentList',
                    apiExtend: 'showList',
                    apiData: {
                        isPayCode: true,
                        isOnlinePayment: true,
                        mountDataOptions: {
                            userMount: {
                                isDept: true,
                            }
                        },

                    }
                }).then(res => {

                    if (res.success) {
                        if (res.data.length === 1) {

                            form.setFieldValue("paymentListId", res.data[0].id)

                        }
                        setPaymentListData(res.data)
                    }
                });
        }

        fetchData()
        initData()

    }, [])

    const changeServiceCharge = (a: any, b: any) => {
        let totalMoney = form.getFieldValue("totalMoney");
        let agioMoney = form.getFieldValue("agioMoney");

        let giftPrice = form.getFieldValue("giftPrice");

        let countSerPrice = form.getFieldValue("countSerPrice");

        // let freePrice = form.getFieldValue("freePrice");




        let serviceRate = b.children;
        let isDerateServiceCharge = form.getFieldValue("isDerateServiceCharge");

        //未算服务费金额
        let recePriceIng = totalMoney - agioMoney - giftPrice;

        //应收服务费
        let shouldServiceCharge = parseFloat((recePriceIng * Number(serviceRate)).toFixed(2));

        //实收服务费
        let serviceCharge = 0;
        //减免服务费

        // = countSerPrice * Number(serviceRate);

        //应收/实付价格(未抹零)(已加服务费)
        let receAndRealPrice: number = 0;
        if (isDerateServiceCharge) {
            //应收/实付价格(未抹零)(减免服务费)
            receAndRealPrice = recePriceIng;
            serviceCharge = 0
        } else {
            //实收服务费
            serviceCharge = parseFloat((countSerPrice * Number(serviceRate)).toFixed(2));
            //应收/实付价格(未抹零)(已加服务费)
            receAndRealPrice = recePriceIng + serviceCharge;
        }

        let derateServiceCharge = shouldServiceCharge - serviceCharge;

        // console.log("receAndRealPrice", receAndRealPrice);
        let smallChangePrice;
        if (Number(receAndRealPrice) < 1) {
            smallChangePrice = receAndRealPrice
        } else {
            smallChangePrice = Math.floor(receAndRealPrice);
        }


        //化抹零后价格
        // let smallChangePrice = Math.floor(receAndRealPrice);
        //化抹零的价格
        let smallCharge = parseFloat((receAndRealPrice - smallChangePrice).toFixed(2));

        setSmallCharge(smallCharge);
        setServiceRate(serviceRate);

        form.setFieldsValue({
            shouldMoney: smallChangePrice,
            // smallChange,
            serviceCharge,
            shouldServiceCharge,
            derateServiceCharge
        })


    }



    /**减免服务费 */
    const isDerateServiceCharge = (e: boolean) => {
        let totalMoney = form.getFieldValue("totalMoney");
        let agioMoney = form.getFieldValue("agioMoney");
        let giftPrice = form.getFieldValue("giftPrice");
        let countSerPrice = form.getFieldValue("countSerPrice");

        // let freePrice = form.getFieldValue("freePrice");


        //未算服务费金额
        let recePriceIng = totalMoney - agioMoney - giftPrice;


        //应收服务费
        let shouldServiceCharge = parseFloat((recePriceIng * Number(serviceRate)).toFixed(2));
        //实收服务费
        let serviceCharge = 0;

        let receAndRealPrice: number = 0;
        if (e) {
            //应收/实付价格(未抹零)(减免服务费)
            receAndRealPrice = recePriceIng;

            serviceCharge = 0
        } else {

            //实收服务费
            serviceCharge = parseFloat((countSerPrice * Number(serviceRate)).toFixed(2));
            //应收/实付价格(未抹零)(已加服务费)
            receAndRealPrice = recePriceIng + serviceCharge;
        }
        //减免服务费
        let derateServiceCharge = shouldServiceCharge - serviceCharge;
        let smallChangePrice;
        if (Number(receAndRealPrice) < 1) {
            smallChangePrice = receAndRealPrice
        } else {
            smallChangePrice = Math.floor(receAndRealPrice);
        }
        //化抹零后价格
        // let smallChangePrice = Math.floor(receAndRealPrice);
        //化抹零的价格
        let smallCharge = parseFloat((receAndRealPrice - smallChangePrice).toFixed(2))

        setSmallCharge(smallCharge);
        form.setFieldsValue({
            shouldMoney: smallChangePrice,
            derateServiceCharge,
            shouldServiceCharge,
            serviceCharge
        })

    }

    const changePayCode = (e: any) => {
        if (e && paymentListData.length === 1) {
            form.setFieldValue("paymentListId", paymentListData[0].id)
        }

    }
    // const initOrderPrint = (apiData?: any) => {


    //    let roomId = apiData?.roomId ? apiData?.roomId : form.getFieldValue("roomId");
    //     let printType = apiData?.printType ? apiData?.printType : form.getFieldValue("printType");
    //     let roomTypeId = apiData?.roomTypeId ? apiData?.roomTypeId : form.getFieldValue("roomTypeId");



    //     fetchApi.GET(wmService, {
    //         apiId: "room",
    //         apiExtend: "getRoomByOrderPrint",
    //         apiData: {
    //             roomId,
    //             printType,
    //             roomTypeId
    //         }
    //     }).then((res) => {

    //         form.setFieldValue("printerIds", res.data)


    //     }).catch((err) => {
    //         form.resetFields(["printerIds"])
    //     })
    // }
    const onChangePrintModeType = (e: any) => {
        switch (Number(e)) {
            case 2:
                // initOrderPrint()
                break;

            default:
                form.resetFields(["printerIds"])
                break;
        }

    }
    // const onChangePrintType = (e: any) => {
    //     if(printModeType===2) initOrderPrint({printType:e})


    // }
    return (
        <>
            <AntDraggableModal
                title={<div>预结订单{data.isTea ? "" : <span style={{ color: "red" }}>(提示:未加入茶位)</span>}</div>}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={"60%"}
                confirmLoading={loading}
            >
                {/* <Row> */}
                {/* <Col span={10}> */}
                <div >
                    <Form
                        form={form}
                        name="Edit"
                        onFinish={handleOk}

                        initialValues={{
                            showDiscount: true,
                            showPerCapita: true,
                            showPeople: true,
                            showName: true,
                            printType: "cateringCheckoutDetailAccountsOrder",

                        }}
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                handleOk(e)
                            }
                        }}
                    >
                        <Row>
                            <Col span={10}>
                                <Col span={24}>
                                    <Form.Item
                                        label="消费总额"
                                        name="totalMoney">
                                        <InputNumber precision={2} disabled addonAfter={"元"} ></InputNumber>
                                    </Form.Item>
                                </Col>



                                <Col span={24}>
                                    <Form.Item
                                        label="折扣额"
                                        name="agioMoney">
                                        <InputNumber addonBefore={<>-</>} precision={2} addonAfter={"元"} disabled ></InputNumber>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="赠送金额"
                                        name="giftPrice">
                                        <InputNumber addonBefore={<>-</>} precision={2} addonAfter={"元"} disabled ></InputNumber>
                                    </Form.Item>
                                </Col>



                                <Col span={24} style={{ display: "none" }}>
                                    <Form.Item
                                        label="服务费金额"
                                        name="countSerPrice">
                                        <InputNumber precision={2} addonAfter={"元"} disabled ></InputNumber>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="服务费率"
                                        name="serviceChargeId"
                                        rules={[{ message: '请选择服务费!', required: true }]}>
                                        <Select
                                            onChange={changeServiceCharge}
                                            showSearch
                                            placeholder="选择服务费"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(serviceChargeData, 'serviceCharge')}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="应收服务费"
                                        name="shouldServiceCharge">
                                        <InputNumber precision={2} disabled addonAfter={"元"} ></InputNumber>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="减免服务费"
                                        name="derateServiceCharge">
                                        <InputNumber addonBefore={<>-</>} precision={2} disabled addonAfter={"元"} ></InputNumber>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="实收服务费"
                                        name="serviceCharge">
                                        <InputNumber precision={2} disabled addonAfter={"元"} ></InputNumber>
                                    </Form.Item>
                                </Col>

                                <Col style={{ display: "none" }} span={24}>
                                    <Form.Item
                                        label="房间id"
                                        name="roomId">
                                        <Input disabled ></Input>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="应收金额"
                                        name="shouldMoney">
                                        <InputNumber precision={2} disabled addonAfter={"元"} ></InputNumber>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="服务费减免"
                                        name="isDerateServiceCharge"
                                        valuePropName="checked"

                                        initialValue={false}>
                                        < Switch checked={false}
                                            onChange={isDerateServiceCharge}
                                            checkedChildren="是"
                                            unCheckedChildren="否" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="打印预结单"
                                        name="isCateringCheckoutDetailAccountsOrder"
                                        valuePropName="checked"

                                        initialValue={true}>
                                        < Switch checked={true}
                                            checkedChildren="是"
                                            unCheckedChildren="否" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="打印模式"
                                        name="printModeType"
                                        rules={[{ message: '请选择打印模式!', required: true }]}>
                                        <Select
                                            popupMatchSelectWidth={150}
                                            showSearch
                                            onChange={onChangePrintModeType}
                                            placeholder="选择打印模式"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(printModeTypeData, 'name')}
                                        </Select>
                                    </Form.Item>
                                </Col>


                                {/**帐单类型 */}
                                <Col span={24}>
                                    <Form.Item
                                        label="帐单类型"
                                        name="printType"
                                        rules={[{ message: '请选择帐单类型!', required: true }]}>
                                        <Select
                                            // onChange={onChangePrintType}
                                            showSearch
                                            placeholder="选择帐单类型"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(accountTypeData, 'name')}
                                        </Select>
                                    </Form.Item>
                                </Col>

                            </Col>
                            <Col span={11}>


                                <Col span={24}>
                                    <Form.Item
                                        label="支付二维码"
                                        name="isPayCode"
                                        valuePropName="checked"

                                        initialValue={false}>
                                        < Switch checked={false}
                                            onChange={changePayCode}
                                            checkedChildren="是"
                                            unCheckedChildren="否" />
                                    </Form.Item>
                                </Col>





                                {isPayCode ? <Col span={24}>
                                    <Form.Item
                                        label=""
                                        name="paymentListId"
                                    >
                                        <Radio.Group>
                                            {paymentListData.length > 0 ?
                                                paymentListData.map((item) => {
                                                    return <Radio value={item.id}>{item.paymentMethodName}</Radio>
                                                })

                                                : null}


                                        </Radio.Group>
                                    </Form.Item>

                                </Col> : ""}





                                <Col style={{ display: "none" }} span={24}>
                                    <Form.Item
                                        label="菜品数量"
                                        name="counts">
                                        <InputNumber></InputNumber>
                                    </Form.Item>
                                </Col>


                                <Col span={24}>
                                    <Form.Item
                                        label="指定打印机"
                                        name="printerIds"
                                    >
                                        <Select
                                            disabled={printModeType === 1 ? true : false}
                                            showSearch
                                            mode="multiple"
                                            allowClear
                                            placeholder="指定打印机"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(printData, 'printerName')}
                                        </Select>
                                    </Form.Item>
                                </Col>





                            </Col>

                        </Row>
                    </Form>



                </div>
                {/* </Col> */}


                {/* </Row> */}

            </AntDraggableModal>
        </>
    )
}
