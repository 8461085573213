import { Table, Button, Space, Input, Tooltip, DatePicker } from 'antd';
import { useState, useEffect } from 'react';
import fetchApi from '../../utils/api/fetchApi'
import antMessage from '../../utils/extend/AntdNotification'
import system from '../../config/systematic';
import survey from '../../generalMethod/survey';
import entrepotPrint from '../../generalMethod/entrepotPrint';
import { showCustomNotification, useNotificationInstance } from '../../utils/extend/AntdNotificationEx';
/** serviceIdType */
// export declare type serviceIdType = 'bm' | 'pay' | 'message';

// export const getService = (serviceId: string) => {
//     /** 默认service */
//     var defaultService = systematic.bmService
//     if (serviceId) {
//         switch (serviceId) {
//             case 'pay':
//                 defaultService = systematic.payService
//                 break;
//             case 'message':
//                 defaultService = systematic.messageService
//                 break;
//         }
//     }
//     return defaultService;
// };
/** WebUniversal ExtendProps数据类型 */
export declare type WUExtendPropsType = {

    /** 编辑组件 */
    EditComponent?: any,


    /** api服务id */
    apiId: string,
    /**apiExtend */
    apiExtend: string,
    /** 主键名称 */
    primaryKeyName?: string,

    /** 表头 */
    columns: Array<any>,

    /**参数 */
    values: any,

    typeProducts:string,
    pagination: boolean,
    printType:string,
    printApi:string
};

/** 刷新数据的类型 */
export declare type initDataType = {
    /** 服务id */
    apiId?: string,
    /** 页面传送的数据 */
    sendPageData?: any
};



/**
 * 通用Web组件 WebUniversal 
 * @param props 参数对象
 * @param ref 返回的对象
 * @returns 
 */
let WebUniversal = (props: any, ref?: any) => {
    let extendProps: WUExtendPropsType;
    extendProps = props.props;
    const notificationApi = useNotificationInstance()

    let {
        EditComponent,
        apiId,
        apiExtend,
        pagination,
        columns,
        values,
        primaryKeyName,
        typeProducts,
        printType,
        printApi
    } = extendProps;

    /** 后端数据wmService */
    const wmService = system.wmService;


    const [current, setCurrent] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [total, setTotal] = useState<number>(0);

    const [rows, setRows] = useState<Array<any>>([]);
    const [level, setLevel] = useState<any>();
    const [initValues, setInitValues] = useState<any>(values);

    const [modalopen, setModalopen] = useState(false);


    const [modalData, setModalData] = useState({});
    /** 页面数据刷新状态 */
    let loading = false;
   

    const initData = (values: any, currentEx?: number, pageSizeEx?: number) => {
        fetchApi.GET(
            wmService,
            {
                apiId,
                apiExtend,
                apiData: {
                    ...values,
                    pageSize: pageSizeEx ? pageSizeEx : pageSize,
                    current: currentEx ? currentEx : current
                }
            }
        ).then(res => {

            setTotal(res.data.total)
            setPageSize(res.data.pageSize)
            setCurrent(res.data.current)
            setInitValues(values)
            setRows(res.data.rows)
            setLevel(res.data.level)
             showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message:"搜索成功",
                                description:  "success"
                                });;
        }).catch((err) => {
            console.log("err", err);

        })
    }
 


    //打开[新增-编辑-复制]框


    // //子组件回调函数，关闭modal
    const onCloseModal = (state: boolean) => {
        setModalopen(false)
        if (state === true) {
            //刷新表格内容
            initData(initValues)
        }
    }

 //编辑
 const handleEdit = (type:string,recordData: any) => {
    
    let id = recordData.id;
    if (typeof primaryKeyName !== "undefined") {
        id = recordData[primaryKeyName]
    }
    openModal(type, id);
};

let sendData = {
    type: '',
    id: '',
    level
    
}
   //打开[新增-编辑-复制]框
   const openModal = (type: string, id: string, ep?: any) => {
    sendData.type = type;
    sendData.id = id;
    // sendData.sendPageData = sendPageData;
    // sendData.extendedParameter = ep ? ep : extendedParameter;
    setModalData(sendData);
    setModalopen(true);
}


    // 表格分页属性
    const paginationProps = {
        showSizeChanger: true,
        showQuickJumper: false,
        showTotal: () => `共${total}条`,
        pageSize,
        current,
        total,
        onShowSizeChange: (current: number, pageSize: number) => changePageSize(pageSize, current),
        onChange: (current: number) => changePage(current, pageSize),
    };

    // 回调函数，切换页数
    const changePage = (current: number, pageSize: number) => {
        if (loading === false) {
            initData(initValues, current, pageSize);
            loading = true;
        }
    }

    // 回调函数,每页显示多少条
    const changePageSize = (pageSize: number, current: number) => {

        if (loading === false) {
            initData(initValues, current, pageSize)
            loading = true;
        }
    };

    useEffect(() => {
        if (values) {
            initData(values)
        }

    }, [values])


    /** 表头 */
    let operationColumns = [
        {
            title: '打印',
            dataIndex: 'print',
            align: "center",
            render: (text: any, record: any) => (<Button disabled={record.orderState === 99 && record.orderStatus === 3 ? false : true} size='small' type='primary' onClick={() => entrepotPrint.printOrder(printType,typeProducts, printApi, undefined, record[primaryKeyName!])}>打印</Button>)
        },
        {
            title: "操作",
            dataIndex: 'operation',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button  size={"small"} type="text" onClick={()=>handleEdit("VIEW",record)} >查看</Button>
                    <Button disabled={level === 1 ? false : record.orderState !== 99 && record.orderStatus === 1 ? false : true} size={"small"} type="text" onClick={()=>handleEdit("EDIT",record)}>编辑</Button>
                    {/* <Button size={"small"} type="text" onClick={()=>handleEdit("EDIT",record)}>编辑</Button> */}

                </Space>
        },
    ]

    columns = columns.concat(operationColumns)
    return (
        <>

            <Table
                id='table'
                rowKey={(record: any, index: any) => index}
                columns={columns}
                dataSource={rows}
                pagination={pagination === true ? paginationProps : false}
                size="small"

            //
            />
            {modalopen ? (<EditComponent closeModal={onCloseModal} data={modalData} />) : null}

        </>
    )
}


export default WebUniversal
