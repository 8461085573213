import { Button, Popconfirm, Switch } from 'antd';
import { useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import MenuCondimentEdit from './MenuCondimentEdit';
import CondimnetWarehouseToMenu from '../menu/CondimnetWarehouseToMenu';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification';
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';

export default function MenuCondiment(props: any) {

    const { data, closeModal } = props;
    const ref = useRef<any>(undefined);

    /** api服务id */
    const apiId = 'menuCondiment';
    const notificationApi = useNotificationInstance()

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    //选择加料
    const [menuData, setMenuData] = useState<any>();

    //转移加料
    const [warehouseToMenuVisible, setWarehouseToMenuVisible] = useState(false);

    //改变数据
    const handleChange = async (id: any, columnName: string, isValue: boolean) => {
        const bodys = {
            apiId: "menuCondiment",
            apiVariable: id,
            apiExtend: "updateSingle",
            apiData: {
                columnName,
                isValue
            }
        }

        fetchApi.PUT(wmService, bodys).then(res => {
             showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message: '编辑成功',
                                    description: 'success',
                                });;
            initData();
        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };

    //表头
    const columnsHead = [
        {
            title: '菜式',
            dataIndex: 'foodName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.menu.foodName}</div>
                )
            }
        },
        {
            title: '加料',
            dataIndex: 'menuCondimentName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.condimentWarehouse.condimentWarehouseName}</div>
                )
            }
        },
        {
            title: '价格',
            dataIndex: 'price',
            render: (text: any, record: any) => {
                return (
                    <div>
                        <span style={{ color: "yellow", fontSize: 20 }}>{Number(record.condimentWarehouse.price + record.price)}</span>
                        <span>&nbsp;&nbsp;</span>
                        <span>{Number(record.condimentWarehouse.price + record.price) === record.condimentWarehouse.price ? "" : " (" + record.condimentWarehouse.price + "→→→" + Number(record.condimentWarehouse.price + record.price) + ")"}</span>
                    </div>

                )
            }
        },
        {
            title: '客服专用',
            dataIndex: 'isStaff',
            render: (text: any, record: any) => {
                return (
                    <Switch checkedChildren="是" unCheckedChildren="否"
                        checked={text} key={record.id}
                        onChange={(e) => handleChange(record.id, 'isStaff', e)}
                    />
                )
            }
        },
        {
            title: '默认',
            dataIndex: 'isDefault',
            render: (text: any, record: any) => {
                return (
                    <Switch checkedChildren="是" unCheckedChildren="否"
                        checked={text} key={record.id}
                        onChange={(e) => handleChange(record.id, 'isDefault', e)}
                    />
                )
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    const handleOk = (e: any) => {
        closeModal(false);
    };

    const initData = async () => {
        // const menuHeadId = data.menuHeadId;
        const currentData: initDataType = {
            apiId,
            // sendPageData: { menuHeadId: menuHeadId }
        }
        ref.current.initData(currentData);
    }

    //添加菜式加料
    const handleAddMenuCondiment = () => {

        sendData.menuHeadId = data.menuHeadId;
        sendData.menuHeadName = data.menuHeadName
        sendData.menuId = data.menuId;
        sendData.branchStoreMenuWarehouseId = data.branchStoreMenuWarehouseId;
        sendData.foodName = data.foodName;
        setMenuData(sendData);
        setWarehouseToMenuVisible(true);
    };

    //关闭穿梭框页面
    const onCloseCondimnetWarehouseToMenu = async (status: boolean) => {
        setWarehouseToMenuVisible(false);
        if (status) {
            await initData();
        }
    }

    //同步到本店其他菜谱
    const handleSynchronous = () => {

        fetchApi.PUT(wmService, {
            apiId: "menuCondiment",
            apiExtend: 'synchronousMenuHead',
            menuId: data.menuId,
            branchStoreMenuWarehouseId: data.branchStoreMenuWarehouseId
        }).then(res => {
            if (res.success === true) {
                antMessage('success', '同步成功', 'success');
            }
        })
    };

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式加料',
        apiId: 'menuCondiment',
        showStates: true,
        showQuery: false,
        showEdit: true,
        showAdd: false,
        showDelete: false,
        columns,
        pagination: true,
        EditComponent: MenuCondimentEdit,
        sendPageData: {
            menuId: data.menuId,
        }
    };

    //传关参数
    let sendData = {
        type: 'EDIT',
        menuHeadId: '',
        menuHeadName: '',
        menuId: '',
        branchStoreMenuWarehouseId: '',
        foodName: '',
    }

    return (
        <>
            <AntDraggableModal
                title={'菜式加料'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={900}>
                <span>&nbsp;&nbsp;</span>
                <Button type="primary" onClick={handleAddMenuCondiment}>添加菜式加料</Button>
                <span>&nbsp;&nbsp;</span>
                <Popconfirm
                    title="把该菜式的加料以【基础价】同步到本店其他菜谱里,是否执行?"
                    onConfirm={() => handleSynchronous()}
                >
                    <Button type="primary">同步到本店其他菜谱</Button>
                </Popconfirm>
                {/* <span>&nbsp;&nbsp;</span>
                <Popconfirm
                    title="把该菜式的加料以【基础价】同步到本菜谱其他菜式里,是否执行?"
                    description="考虑中。。。。。。。。。。。。。。。。。"
                // onConfirm={() => handleAddMenuCondiment()}
                >
                    <Button type="primary">同步到本菜谱其他菜式</Button>
                </Popconfirm> */}
                <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
            </AntDraggableModal>

            {warehouseToMenuVisible
                ? (<CondimnetWarehouseToMenu
                    closeModal={onCloseCondimnetWarehouseToMenu} data={menuData} />)
                : null}

        </>
    )
}