import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Tooltip } from 'antd';
import fetchApi from '../../../../utils/api/fetchApi';
import systemConfig from '../../../../config/systematic';
import antMessage from '../../../../utils/extend/AntdNotification';
import getList from '../../../universal/getList';
import { handleData } from './DailyReport';
import DownloadExcel from '../../../report/DownloadExcel';
import { showCustomNotification, useNotificationInstance } from '../../../../utils/extend/AntdNotificationEx';

export default function ProductQuery() {
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [form] = Form.useForm();
    const [rows, setRows] = useState<Array<any>>([])
    const [areaData, setAreaData] = useState<any>();
    const [classificationWarehouseData, setClassificationWarehouseData] = useState<any>();
    const [queryCriteria, setQueryCriteria] = useState<any>("")
    const notificationApi = useNotificationInstance()

    useEffect(() => {
        const fetchData = async () => {
            let areaData = await fetchApi.GET(wmService, {
                apiId: "area",
                apiExtend: 'showList'
            })
            if (areaData.success) {
                setAreaData(areaData.data)
            }
            let classificationWarehouseData = await fetchApi.GET(wmService, {
                apiId: "classificationWarehouse",
                apiExtend: 'showList'
            })



            if (classificationWarehouseData.success) {

                setClassificationWarehouseData(classificationWarehouseData.data)
            }
        }

        fetchData()
    }, [])
    const columnsHead = [
        {
            title: '区域',
            dataIndex: 'areaName',
            // render: (text: any) => (text ? text.toFixed(2) : "0.00")
        },
        {
            title: '分类',
            dataIndex: 'classificationWarehouseName',
        },
        {
            title: '品号',
            dataIndex: 'selfId',
            // render: (text: any) => (text ? text.deptName : "")
        },
        {
            title: '品名',
            dataIndex: 'productName',
            // render: (text: any) => (text ? text.deptName : "")
        },
       
        {
            title: '单位',
            dataIndex: 'productUnitName',
        },

    ];
    const onFinish = () => {
        form.validateFields()
            .then((values: any) => {
                fetchApi.GET(
                    wmService,
                    {
                        apiId: "entrepotReport",
                        apiExtend: 'productQuery',
                        apiData: {
                            areaId: values.areaId,
                            classificationWarehouses: handleData(values.classificationWarehouses, "classificationWarehouseId"),
                        }
                    }
                ).then(res => {
                    setQueryCriteria(res.data.queryCriteria)
                    setRows(res.data.rows)
                     showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message:"搜索成功",
                                description:  "success"
                                });;

                }).catch((err) => {
                    antMessage('error', '搜索失败', err);

                })
            }).catch((err) => {
                 showCustomNotification(notificationApi!, {
                                    type: 'warning',
                                    message:"无法通过数据校验",
                                description:  "请输入所需数据!"
                                });;

            })
    }
    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Row>

                    <Col span={3}>
                        <Form.Item
                            label="区域"
                            name="areaId"

                        >
                            <Select
                                showSearch
                                placeholder="区域"
                                optionFilterProp="children"
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(areaData, 'areaName')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="品类"
                            name="classificationWarehouses"
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="品类"
                                optionFilterProp="children"
                                onSearch={(e) => getList.search('classificationWarehouse', setClassificationWarehouseData, e)}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(classificationWarehouseData, 'classificationWarehouseName')}
                            </Select>
                        </Form.Item>
                    </Col>






                    <Col span={2}>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    查询
                                </Button >
                                <Button onClick={() => DownloadExcel(rows, "productQuery", queryCriteria)}>下载EXCEL文件</Button>
                                {/* <Button onClick={() => DownloadExcel(prop, prop.data)} >
                          下载EXCEL文件
                      </Button> */}
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>



            {/* <Button onClick={() => DownloadExcel(rows, "moonReport",queryCriteria)}>下载EXCEL文件</Button> */}
            <div style={{ marginTop: "20px" }}>
                <Table
                    scroll={{ x: "100%", y: 650 }}
                    pagination={false}
                    rowKey={(record: any) => record.id}
                    columns={columnsHead}
                    dataSource={rows}

                >
                </Table>

            </div>
        </>
    )
}
