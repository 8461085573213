/** 数组处理 */
const arrayHandle = async () => { };


/**
 * 查找数组中匹配的数据
 * @param rows 数组
 * @param idName 匹配id名称
 * @param idValue 匹配id值
 * @returns 返回find找到的数据数组 
 */
arrayHandle.findData = (rows: Array<any>, idName: any, idValue: any): Array<any> => {
    // if (typeof rows === 'undefined') return 
    // if (rows.length === 0) return 
    let cc = [...rows];
    
    let data: Array<any> = [];
    cc.forEach((item) => {
        if (item[idName] === idValue) {
            let obj = {
                ...item,
               
            }
            data.push(obj)
        }
    })

    return data
}

/**
 * 查找数组中匹配的数据
 * @param rows 数组
 * @param idName 匹配id名称
 * @param idValue 匹配id值
 * @param targetId ? 匹配id行的目标Id，如果存在，返回值，否则返回数组内容
 * @returns 返回find到第一个值的数据对象 { index-索引, data-数据 }
 */
arrayHandle.findValue = async (rows: any, idName: any, idValue: any, targetId?: any) => {
    if (typeof rows === 'undefined') { return undefined; }
    if (rows.length === 0) { return undefined; }
    /** 索引 */
    const index = await rows.findIndex((item: any) => item[idName] === idValue);
    /** 数据 */
    let data;
    if (index > -1) {
        data = rows[index];
        if (targetId) {
            data = data[targetId];
        }
    }
    return {
        /** 索引 */
        index,
        /** 数据 */
        data
    };
};

/**
 * 删除数组中匹配的数据
 * @param rows 数组
 * @param idName 匹配id名称
 * @param idValue 匹配id值
 * @returns 返回删除后的数组
 * @example arrayHandle.deleteValue(rows,'id','xx')
 */
arrayHandle.deleteValue =  (rows: any, idName: any, idValue: any) => {
    if (typeof rows === 'undefined') { return undefined; }
    if (rows.length === 0) { return undefined; }
    /** 索引 */
    const index = rows.findIndex((item: any) => item[idName] === idValue);
    if (index > -1) {
        //删除数组方法，不要用delete
        rows.splice(index, 1);
    }
    return rows;
};

/**
 * 数组合并，先对旧数组替换为新数组数据，对新数组去重，然后再concat合并新数据
 * @param oldArray 旧数组对象
 * @param NewArray 新数组对象
 * @param keyId ? 去重关键id名称，默认是'id'
 */
arrayHandle.merge = (oldArray: Array<any>, NewArray: Array<any>, keyId?: string) => {
    let res:Array<any>=[]
    if (oldArray.constructor === Array && NewArray.constructor === Array) {
        if (typeof keyId === 'undefined') {
            keyId = 'id';
        }
        /** 新数组长度 */
        let arrayTwoLength = NewArray.length;
        for (var i = 0; i < oldArray.length; i++) {
            for (var j = 0; j < arrayTwoLength; j++) {
                if (oldArray[i][keyId] === NewArray[j][keyId]) {
                    //替换旧数据内容
                    oldArray[i] = NewArray[j];
                    //删除新数据中相同的数据
                    NewArray.splice(j, 1);
                    arrayTwoLength--;
                }
            }
        }
        res = oldArray.concat(NewArray);
    }
    return res;
};

/** 排序类型 */
export declare type sortByType = 'asc' | 'desc';

/**
 * 数组排序(递归法)
 * @param arr 数组
 * @param prop 排序依据属性，如无属性值，可用undefined，如错误，则不会排序
 * @param sortBy ? 排序模式(默认desc)
 * @returns 
 */
arrayHandle.sort = (arr: Array<any>,
    prop?: string, sortBy?: sortByType): Array<any> => {
    /** 返回的数组 */
    let res = arr;
    // 没有元素或只有1个元素 无需排序
    if (arr.length > 1) {
        try {
            /** 第一个元素作为基准值 */
            let piv;
            if (typeof prop !== 'undefined') {
                piv = arr[0][prop];
            }
            else {
                piv = arr[0];
            }
            /** 左侧 < 基准值数组 */
            let Larr = [];
            /** 右侧 > 基准值数组 */
            let Rarr = [];
            for (let i = 1; i < arr.length; i++) {
                /** 比较值 */
                let comparePiv;
                if (typeof prop !== 'undefined') {
                    comparePiv = arr[i][prop];
                }
                else {
                    comparePiv = arr[i];
                }
                if (comparePiv < piv) {
                    Larr.push(arr[i]);
                } else {
                    Rarr.push(arr[i]);
                }
            }
            // 递归调用sort 结果返回新数组
            res = [...arrayHandle.sort(Rarr, prop), arr[0], ...arrayHandle.sort(Larr, prop)];
            switch (sortBy) {
                case 'asc':
                    //反转数组中元素的顺序：
                    res = res.reverse();
                    break;
            }
        }
        catch {
            res = ['参数错误!'];
        }
    }
    return res;
};

/**
 * 数组截取
 * @param arr 数组
 * @param truncateLenght 截取长度
 * @param direction ? 截取方向，是否截取右长度
 * @example let arr = ['a', 'b', 'c', 'd', 'e', 'f','g','h'];
 * arrayHandle.truncate(arr, 4) return 'a','b','c','d';
 * arrayHandle.truncate(arr, 4, true) return 'e','f','g','h';
 */
arrayHandle.truncate = (arr: Array<any>,
    truncateLenght: number, direction?: boolean) => {
    const len = arr.length;
    const i = len - truncateLenght;
    if (i > 0) {
        let tArray;
        if (direction === true) {
            tArray = arr.slice(i, len);
        }
        else {
            tArray = arr.slice(0);
            tArray.splice(truncateLenght, i);
        }
        return tArray;
    }
    else {
        return arr;
    }
};

/**
 * 数组重排序
 * @param arr 数组
 * @param index 数据中的索引
 * @param prefix ? 排序是否放在最前
 * @returns 
 */
arrayHandle.reorder = (arr: Array<any>,
    index: number, prefix?: boolean) => {
    const len = arr.length;
    if (len === 0 || index >= len) {
        return arr;
    }
    else {
        let res;
        let newItem = arr[index];
        let tArray = arr.slice(0);
        tArray.splice(index, 1);
        if (prefix === true) {
            res = [newItem].concat(tArray);
        } else {
            res = tArray.concat(newItem);
        }
        return res;
    }
};

export default arrayHandle;