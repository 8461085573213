import React, { useState, useEffect } from 'react'
import { Select, Button, Col, Image, Form, Row, Space, Table, Tooltip, DatePicker } from 'antd';
import systemConfig from '../../../../config/systematic';
import fetchApi from '../../../../utils/api/fetchApi';
import getList from '../../../universal/getList';
import PurchaseOrderEdit from '../../orderManagement/PurchaseOrderEdit';
import moment from 'moment';
import antMessage from '../../../../utils/extend/AntdNotification'
import dayjs from 'dayjs';
import { handleData } from './DailyReport';

import WebUniversalReport, { WUExtendPropsType } from '../../../system/WebUniversalReport';
import { showCustomNotification, useNotificationInstance } from '../../../../utils/extend/AntdNotificationEx';


const { RangePicker } = DatePicker;

export default function PurQuery() {
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [deptData, setDeptData] = useState<any>();
    const [buyerData, setBuyerData] = useState<any>();
    const [form] = Form.useForm();

    const notificationApi = useNotificationInstance()


    /**打开收货订单窗口 */

    const [initValues, setInitValues] = useState<any>();

    useEffect(() => {
        const fetchData = async () => {
            form.setFieldValue("chooseTime", [dayjs(), dayjs()])
            let deptData = await fetchApi.GET(wmService, {
                apiId: "dept",
                apiExtend: 'showList',
                apiData: {

                }
            })
            if (deptData.success) {
                setDeptData(deptData.data)
            }
            let buyerData = await fetchApi.GET(wmService, {
                apiId: "buyer",
                apiExtend: 'showList'
            })



            if (buyerData.success) {

                setBuyerData(buyerData.data)
            }
        }
        fetchData()
    }, [])


    /** 表头内容 */
    let columnsHead: any = [
        {
            title: "单号",
            dataIndex: "id",
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 6) + '....' : text)
                    : ''
            }</Tooltip>
            // render: (text: any) => (text ? text.selfId ? text.selfId : "" : "")
        },
        {
            title: "部门",
            dataIndex: "dept",
            render: (text: any) => (text ? text.deptName : ""),
            align: "center"
        },
        {
            title: '仓库',
            dataIndex: 'entrepot',
            render: (text: any) => (text ? text.entrepotName : ""),
            // ellipsis:true
            align: "center"

        },
        {
            title: '采购商',
            dataIndex: 'buyer',
            render: (text: any) => (text ? text.buyerName : ""),
            align: "center"

        },
        {
            title: "日期",
            dataIndex: "sysDate",
            align: "center",

            render: (val: any, record: any) =>
                <span>
                    {moment(val.createdAt).format('YYYY-MM-DD')}
                </span>,

        },
        {
            title: '流程',
            dataIndex: 'totalOrderProcessName',
            align: "center",
            width: 70,
            render: (text: any) => <div style={{ "fontSize": 12.5 }}>{text ? text : ""}</div>
        },
        {
            title: '订单状态',
            dataIndex: 'orderStatusName',
            align: "center",
            width: 75,
            render: (text: any, record: any) => {

                return <div style={record.orderStatus === 4 ? { color: "red", "fontSize": 12.5 } : { "fontSize": 12.5 }}>{text ? text : ""}</div>

            }
        },
        {
            title: "金额",
            align: "center",
            dataIndex: 'sumTotalPrice',
            render: (text: any) => <div>{text ? text.sumPrice : ""}</div>
        },


    ]



    const onFinish = () => {
        form.validateFields()
            .then((values: any) => {
                setInitValues({
                    deptIds: handleData(values.deptIds, "deptId"),
                    buyers: handleData(values.buyers, "buyerId"),
                    time: values.chooseTime ? {
                        startTime: dayjs(values.chooseTime[0]).format("YYYY-MM-DD"),
                        endTime: dayjs(values.chooseTime[1]).format("YYYY-MM-DD"),
                    } : null,
                })

            }).catch((err) => {
                 showCustomNotification(notificationApi!, {
                                    type: 'warning',
                                    message:"无法通过数据校验",
                                description:  "请输入所需数据!"
                                });;

            })
    }


    let extendProps: WUExtendPropsType = {
        apiId: 'entrepotReport',
        apiExtend: "purQuery",
        columns: columnsHead,
        pagination: true,
        values: initValues,
        primaryKeyName: "id",
        printType: "purchaseOrderPrint",
        typeProducts: "purchaseProducts",
        // primaryKeyName:"orderId",
        printApi: "purchaseOrder",
        EditComponent: PurchaseOrderEdit,
    };
    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Row>

                    <Col span={16}>
                        <Form.Item
                            label="部门"
                            name="deptIds"
                        >
                            <Select
                                // orderTypeData
                                mode="multiple"
                                showSearch
                                placeholder="请选择部门!"
                                optionFilterProp="children"

                                onSearch={(e) => getList.search('dept', setDeptData, e)}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(deptData, 'deptName')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="采购商"
                            name="buyers"
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="采购商"
                                optionFilterProp="children"
                                onSearch={(e) => getList.search('buyer', setBuyerData, e)}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(buyerData, 'buyerName')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="日期"
                            name="chooseTime"
                        >
                            <RangePicker
                                defaultValue={[dayjs(), dayjs()]}
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </Col>



                    <Col span={2}>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    统计
                                </Button >
                                {/* <Button onClick={() => DownloadExcel(prop, prop.data)} >
                                  下载EXCEL文件
                              </Button> */}
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>



            {/* <Button onClick={() => DownloadExcel(rows, "moonReport",queryCriteria)}>下载EXCEL文件</Button> */}
            {/* <div style={{ marginTop: "20px" }}>
                <Table
                    pagination={paginationProps}
                    rowKey={(record: any, index: any) => index}

                    columns={columnsHead}
                    dataSource={rows}
                // onScroll={(e)=>handleScroll(e)}
                >
                </Table>
                {modalPurOrderOpen ? (<PurchaseOrderEdit data={purOrderData} closeModal={onClosePurOrderOpen}
                />)
                    : null}
            </div> */}
            <WebUniversalReport props={extendProps}></WebUniversalReport>
        </>
    )
}
