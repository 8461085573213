import { useEffect, useRef, useState } from 'react';
import FilesEdit from './FilesEdit';
import WebUniversal, { initDataType, WUExtendPropsType } from '../system/WebUniversalNewEx'
import { columnsheadId } from '../universal/columnshead';
import { Tooltip, Image, Button, Upload, Select, Space } from 'antd';
import systematic from "../../config/systematic";
import fetchApi from '../../utils/api/fetchApi';
import PhotoGallery from './PhotoGallery';
import antMessage from '../../utils/extend/AntdNotification';
import numberHandle from '../../utils/baseLib/numberHandle';
import { UploadOutlined } from '@ant-design/icons';
import filesSystem from '../../config/filesSystem';
import { showCustomNotification, useNotificationInstance } from '../../utils/extend/AntdNotificationEx';
import getList from '../universal/getList';

/**
 * 组件 Files 
 * @param props 
 * @returns 
 */
const Files = (props: any) => {
    const apiId = 'files';
    const [photoGalleryVisible,
        setPhotoGalleryVisible] = useState(false);
    //文件组内容
    const [filesGroupData, setFilesGroupData] = useState<any>();
    //文件组id
    const [filesGroupId, setFilesGroupId] = useState<any>();
    //默认文件组
    const [defaultMenu, setDefaultMenu] = useState<any>();

    //刷新变量
    const [refresh, setRefresh] = useState(0);
    //刷新变量
    const [wipeRefresh] = useState(0);
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>(undefined);
    /** 刷新数据 */
    let initData: any;
    /** 清空数据 */
    let wipeData: any;
    /** 后端数据 */
    let wmService: any = systematic.wmService;
    /** filesService */
    const [filesService, setFilesService] = useState<any>();
    /** filesServerUrl */
    const [filesServerUrl, setFilesServerUrl] = useState<any>();
    /** 服务id */
    const [serviceId, setServiceId] = useState<any>();
    const notificationApi = useNotificationInstance()

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.defaultService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wipeData = ref.current.wipeData;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService,
                {
                    apiId: 'filesGroup',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setFilesGroupData(res.data);
                    }
                })
            const defaultFilesServer = await filesSystem.getDefaultServer();
            if (defaultFilesServer) {
                const { defaultServiceId, api } = defaultFilesServer;
                const { apiServiceUrl, url } = api;
                setFilesService(apiServiceUrl);
                setFilesServerUrl(url);
                setServiceId(defaultServiceId);
            }
        };
        fetchData();
        if (wipeRefresh !== 0) {
            wipeData();
        };
        if (refresh !== 0) {
            initData();
        };
    }, [refresh, wipeRefresh]); //初始化数据

    //是否图片
    const isImage = (fileType: string) => {
        let returnData = false
        if (fileType.slice(0, 5) === 'image') {
            returnData = true
        }
        return returnData;
    };

    /**
     * 点击查看图库时触发
     */
    const handlePhotoGallery = async (record: any) => {

        const { filesGroupId } = record;
        setDefaultMenu(filesGroupId);
        setPhotoGalleryVisible(true);
    };

    /**
     * 关闭图库
     */
    const closePhotoGallery = (state: boolean) => {
        setPhotoGalleryVisible(false);
        setRefresh(numberHandle.rangeNumber(1, 999));
    };

    /**
     * 获取文件地址
     * @param record 
     * @param isDownload 是否是下载
     * @returns 
     */
    const getFile = (record: any, isDownload?: boolean) => {
        if (typeof filesServerUrl === 'undefined') {
            return undefined;
        }
        const { destination, fileName, smallImage, serverUrl } = record;
        const filePath = serverUrl + "/" + destination + "/" +
            (isDownload === true ? fileName : (smallImage ? smallImage : fileName));
        return filePath;
    };

    /**
     * 覆盖删除按钮方法
     * @param record 
     */
    const coverHandleDelete = async (record: any, isChecked: boolean) => {
        try {
            //删除文件管理的数据(物理删除)
            const res = await fetchApi.DELETE(
                wmService,
                {
                    apiId: 'files',
                    apiExtend: 'deleteFiles',
                    apiData: {
                        filesIds: [{ id: record.id }]
                    }
                })
            if (res.success === true) {
                showCustomNotification(notificationApi!, {
                    type: 'success',
                    message: "删除成功",
                    description: "success"
                });;
                setRefresh(numberHandle.rangeNumber(1, 999));
            }
            else {
                showCustomNotification(notificationApi!, {
                    type: 'error',
                    message: "删除失败",
                    description: "error"
                });;
            }
        }
        catch (err: any) {
            showCustomNotification(notificationApi!, {
                type: 'error',
                message: '删除失败',
                description: err.message + '，[' + err.errors.status + ']'
            });
        }
    };

    /**
     * 文件上传
     */
    const upload = (filesId: string) => {
        return {
            name: 'file',
            action: filesService,
            headers: {
                apiId: 'files',
                apiExtend: 'updateSingleFile',
                filesId,
                serviceId
            },
            async onChange(info: any) {
                if (info.file.status === 'uploading') {
                    // setWipeRefresh(numberHandle.RangeNumber(1, 999));
                }
                if (info.file.status === 'done') {
                    if (info.file.response.success) {
                        showCustomNotification(notificationApi!, {
                            type: 'success',
                            message: '文件上传成功',
                            description: "success"
                        });;
                        //刷新
                        // setWipeRefresh(numberHandle.RangeNumber(1, 999));
                        // setRefresh(numberHandle.RangeNumber(1, 999));
                        window.location.reload();
                    }
                    else {
                        antMessage('error', '文件上传失败', info.file.response.message);
                    }
                } else if (info.file.status === 'error') {
                    antMessage('error', '文件上传失败', info.file.response.message);
                }
            },
        }
    };

    /**
     * 刷新数据
     * @param options 选项
     */
    const refreshData = (options: {
        currentFilesGroupId?: string,
        currentSendPageData?: any
    }) => {
        let { currentFilesGroupId, currentSendPageData } = options;
        currentSendPageData = typeof currentSendPageData !== 'undefined' ? currentSendPageData : { filesGroupId }
        if (typeof currentFilesGroupId !== 'undefined') {
            currentSendPageData = {
                ...currentSendPageData,
                filesGroupId: currentFilesGroupId
            }
        }
        const currentData: initDataType = {
            apiId,
            sendPageData: currentSendPageData
        }
        ref.current.initData(currentData);
    };

    /**
     * 选择文件组
     * @param e 
     */
    const handleSelectFilesGroup = (e: any) => {
        refreshData({
            currentFilesGroupId: e
        })
        setFilesGroupId(e);
    };

    /** 表头内容 */
    const columnsHead = [
        {
            title: '文件组',
            dataIndex: 'filesGroup',
            render: (text: any) => (text.groupName)
        },
        {
            title: '子目录',
            dataIndex: 'subdirectory',
            render: (text: any, record: any) => {
                const { subdirectory, secondSubdirectory } = record;
                /** 合并的子目录 */
                const merDirectory = subdirectory + (secondSubdirectory ? '/' + secondSubdirectory : '');
                <Tooltip placement="topLeft" title={merDirectory}>{
                    (typeof merDirectory !== "undefined" && merDirectory !== null ?
                        merDirectory.length >= 8 ? merDirectory.substr(0, 8) + '......' : merDirectory
                        : '')
                }</Tooltip>
            }
        },
        {
            title: '日期目录',
            dataIndex: 'dateSubdirectory',
        },
        {
            title: '图库',
            dataIndex: 'checkPhoto',
            render: (text: any, record: any) => (
                <Button size={"small"} type="primary" onClick={() => handlePhotoGallery(record)}>图库</Button>
            )
        },
        {
            title: '名称',
            dataIndex: 'originalFileName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substr(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '文件',
            dataIndex: 'fileName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substr(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '目录',
            dataIndex: 'destination',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substr(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '图片',
            dataIndex: 'id',
            render: (text: any, record: any) => {
                return isImage(record.fileType) ?
                    < Image
                        src={getFile(record)}
                        width={60}
                        alt="图片" /> :
                    <text>{record.fileType}</text>
            }
        },
        {
            title: '下载',
            dataIndex: 'id',
            render: (text: any, record: any) =>
                <a href={getFile(record, true)} >
                    <Tooltip title={'请用鼠标右键点击下载（另存）！'}>下载</Tooltip></a>
        },
        {
            title: '更新',
            dataIndex: 'id',
            render: (text: any) => (
                <Upload
                    {...upload(text)}
                    showUploadList={false}>
                    <Button icon={<UploadOutlined />}>更新</Button>
                </Upload>
            )
        }
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '文件',
        apiId,
        columns,
        showStates: true,
        showAdd: false,
        pagination: true,
        sendPageData: {},
        EditComponent: FilesEdit,
        coverHandleDelete,
    };

    return (
        <> <Space>
            <Select
                showSearch
                placeholder="图片组"
                optionFilterProp="children"
                onChange={handleSelectFilesGroup}
                // value={loggerId}
                style={{ width: 150 }}
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.generalEx(filesGroupData, { itemName: 'groupName', includeNull: true })}
            </Select>
            <Button onClick={handlePhotoGallery}>打开图库</Button>
        </Space>
            <WebUniversal props={extendProps} ref={ref} />
            {photoGalleryVisible
                ? (<PhotoGallery
                    closeModal={closePhotoGallery} filesGroupData={filesGroupData}
                    defaultMenu={defaultMenu} uploadFileParam={{
                        url: filesServerUrl,
                        apiServiceUrl: filesService,
                        serviceId
                    }} />)
                : null}
        </>
    )
}

export default Files;